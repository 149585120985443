import { TimestampProps } from '@src/client/helpers/reports/types';
import { ReportType } from '@src/client/lib/api/types/response';
import { CommOptionsKeys } from '@src/client/modules/engage/campaign/types';
import { ReactNode } from 'react';

export enum EngageAttributionEndpoints {
  overallStats = '/campaign/{item_ext_id}/statistics',
  engageReport = '/campaign/{item_ext_id}/report',
  engageUsers = '/campaign/{item_ext_id}/statistics/users',
}

export enum CommunicationEvents {
  COMMUNICATION_SENT = 'COMMUNICATION_SENT',
  COMMUNICATION_RECEIVED = 'COMMUNICATION_RECEIVED',
  COMMUNICATION_DELIVERED = 'COMMUNICATION_DELIVERED',
  COMMUNICATION_VIEWED = 'COMMUNICATION_VIEWED',
  COMMUNICATION_CLICKED = 'COMMUNICATION_CLICKED',
  COMMUNICATION_FAILED = 'COMMUNICATION_FAILED',
}

export enum CommunicationSteps {
  INITIATED = 'INITIATED',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  VIEWED = 'VIEWED',
  CLICKED = 'CLICKED',
  FAILED = 'FAILED',
}

export enum CommunicationTypeColours {
  PUSH_NOTIFICATION = 'F79009',
  SMS = '717BBC',
  WHATSAPP = '17B26A',
  EMAIL = '3E4784',
}

export interface EngageAttributionReportRequest {
  itemExternalId: string;
  reportType: ReportType;
  isPrimaryOnly?: boolean;
  isReminderOnly?: boolean;
  reminderIndex?: number;
  timestampProps?: TimestampProps;
  communicationType?: CommOptionsKeys;
}

export interface EngageAttributionUserRequest {
  itemExternalId: string;
  limit: number;
  offset: number;
  communicationStep: CommunicationSteps;
  reminderIndex?: number;
}

export interface EngageAttributionUserResponse {
  user_id: string;
  event_name: string;
  reminderindex?: number;
  isreminder?: boolean;
  remark?: string;
  time: string;
  communication_medium: string;
}

export interface EngageUsersCSV {
  time: string;
  user_id: string;
  communication_step?: CommunicationSteps;
  communication_medium: string;
  reminderIndex?: number;
  isReminder?: boolean;
  remark?: string;
}

interface EngageOverallMetrics {
  initiated: number;
  sent: number;
  delivered: number;
  viewed: number;
  clicked: number;
  failed: number;
  error: number;
}

export interface OverallMetricsResponse {
  itemExternalId: string;
  statistics: EngageOverallMetrics;
}

export interface FunnelBarReportData {
  color: string;
  key: string;
  event: string;
  value: number;
  step_index: string;
  breakdown: string;
}

export interface EngageAttributionUserType {
  userId: string;
  isReminder?: boolean;
  reminderIndex?: number;
  time: string;
  eventName: string;
}

export interface OverviewMetricsProps {
  itemExtId: string;
}

export interface StatisticsList {
  key: CommunicationSteps;
  name: string;
  icon: ReactNode;
  value: number;
  description: string;
  formula: string;
  conversionRate: number | string; // Use number or 'N/A' for conversion rate display
}
