export const QueryKeys = {
  GetDashboardSummariesByUser: 'GetDashboardSummariesByUser',
  GetRecentlyViewed: 'GetRecentlyViewed',
  GetEventProperties: 'GetEventProperties',
  GetDetailedDimensions: 'GetDetailedDimensions',
  GetAllEvents: 'GetAllEvents',
  GetCustomEvents: 'GetCustomEvents',
  GetCampaigns: 'GetCampaigns',
  GetAlerts: 'GetAlerts',
  GetCohorts: 'cohort_name',
  GetFlexicasts: 'GetFlexicasts',
  GetExperiments: 'GetExperiments',
  GetFeatureFlags: 'GetFeatureFlags',
};
