import { fetchEndpoint, PlatformService } from '@src/client/lib/api/utils';

import { SdkIntegrationResponse } from '../types';
import { SdkIntegrationEndpoints } from './endpoints';

export const getSdkItegrations = async (sdkTypes: string[]) => {
  const data: SdkIntegrationResponse = await fetchEndpoint(
    SdkIntegrationEndpoints.getSdkItegrations,
    {},
    { sdkTypes },
    {},
    'GET',
    PlatformService,
  );
  return data;
};
