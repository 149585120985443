import { CohortEndpoints } from '@src/client/modules/cohorts/api/endpoints';
import { BasicUserInfo, PerceptUserInfo, TeamInfoDetailed } from '@src/client/modules/uam/types';

import {
  AlertEndpoints,
  CustomEventsEndpoints,
  DashboardEndpoints,
  EventEndpoints,
  ReportEndpoints,
  RoleEndpoints,
  RunEndpoints,
  SearchEndpoints,
  TeamEndpoints,
  UserEndpoints,
  UserInfoEndpoints,
} from '../constants';
import {
  AlertExtId,
  AlertItemId,
  CreateReportUsersCohortRequest,
  DetectGetItemRequest,
  GetAnomaliesForReportRequest,
  GetCustomEventsCountRequest,
  GetCustomEventsRequest,
  GetDashboardCountRequest,
  GetDashboardRequest,
  GetListRequest,
  GetRecentItemsRequest,
  GetRecentlyViewedRequest,
  GetReportCountRequest,
  GetReportUsersRequest,
  GetSearchCreatorRequest,
  GetSearchRequest,
  GetUserAlertCountRequest,
  GetUserAlertRequest,
  GetUserCohortCountRequest,
  GetUserCohortRequest,
  GetUserReportRequest,
  GetUsersInTeamRequest,
  RequestByItemExtId,
  SearchDimensionValuesRequest,
} from '../types/request';
import {
  AlertConfig,
  AnomalySummary,
  CohortBasicItem,
  CohortItemResponse,
  CohortSummaryResponse,
  // CompleteUserInfo,
  CustomEventDTO,
  DashboardSummaryResponse,
  DefaultDashboardSummaryResponse,
  DimensionDetailed,
  EventItemResponse,
  ItemCountResponse,
  MyEventItemResponse,
  RecentlyAccessedItems,
  RecentlyViewedItemResponse,
  ReportAlertSummary,
  ReportCountResponse,
  ReportItemResponse,
  ReportSummaryResponse,
  RunStatusResponse,
  SearchItem,
  UserDetail,
  UserInfo,
} from '../types/response';
import { fetchEndpoint, PlatformService, UserGovService } from '../utils';

/* DP: User Info */

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-promise-executor-return
export const sleep = (ms: any) => new Promise((resolve) => setTimeout(() => resolve(null), ms));

export const updateLoginTenant = async (tenantId: string) =>
  fetchEndpoint(UserInfoEndpoints.updateLoginTenant, {}, { tenantId }, {}, 'GET', UserGovService);

/* User */

export const getUserInfoOld = async () => {
  const data: UserInfo = await fetchEndpoint(UserEndpoints.userInfo, {}, {}, {}, 'GET', UserGovService);
  return data;
};

/** Get All Events */
export const getProperties = async () => {
  const data: Array<DimensionDetailed> = await fetchEndpoint(
    ReportEndpoints.dimensions,
    {},
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

/** Get cohort values */
export const getValuesByCohortName = async () => {
  const data: CohortBasicItem[] = await fetchEndpoint(CohortEndpoints.basicList, {}, {}, {}, 'GET', PlatformService);
  return data;
};

/** Get total Cohort Summaries for a user */
export const getCohortCount = async (request: GetUserCohortCountRequest) => {
  const data: ItemCountResponse = await fetchEndpoint(
    CohortEndpoints.cohortCount,
    {},
    request,
    {},
    'GET',
    PlatformService,
  );
  return data;
};

/** Get Values of a specific event */
export const getValuesByEventName = async (dimension: string) => {
  const data: Array<string> = await fetchEndpoint(
    ReportEndpoints.dimensionValues,
    {
      dimension,
    },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

/** Search dimension values */
export const searchDimensionValues = async (searchDimensionValuesRequest: SearchDimensionValuesRequest) => {
  const data: Array<string> = await fetchEndpoint(
    ReportEndpoints.searchDimensionValues,
    { dimension: searchDimensionValuesRequest.dimension, searchString: searchDimensionValuesRequest.searchString },
    { limit: searchDimensionValuesRequest.limit, offset: searchDimensionValuesRequest.offset },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

/** Get the run details for a specific runId */
export const getRunByRunId = async (runId: string | undefined) => {
  if (!runId) return null;
  const data: RunStatusResponse = await fetchEndpoint(
    RunEndpoints.runStatus,
    { runId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

/** Get the list of Insight Summaries for a user */
export const getReportSummariesByUser = async (request: GetUserReportRequest) => {
  const data: ReportSummaryResponse[] = await fetchEndpoint(
    ReportEndpoints.reportSummariesList,
    {},
    request,
    {},
    'GET',
    PlatformService,
  );
  return data;
};

/** Get the list of Insight Summaries for a user */
export const getReportCount = async (request: GetReportCountRequest) => {
  const data: ReportCountResponse = await fetchEndpoint(
    ReportEndpoints.reportCount,
    {},
    request,
    {},
    'GET',
    PlatformService,
  );
  return data;
};

/** Get the list of Cohort Summaries for a user */
export const getCohortSummariesByUser = async (request: GetUserCohortRequest) => {
  const data: CohortSummaryResponse[] = await fetchEndpoint(
    CohortEndpoints.cohortSummariesList,
    {},
    request,
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getRecentlyAccessedItems = async (request: GetRecentItemsRequest) => {
  const data: RecentlyAccessedItems[] = await fetchEndpoint(
    UserEndpoints.recentItems,
    {},
    request,
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getMyReports = async (request: GetRecentItemsRequest) => {
  const data: ReportSummaryResponse[] = await fetchEndpoint(
    UserEndpoints.myReports,
    {},
    request,
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getCohortsList = async () => {
  const data: CohortBasicItem[] = await fetchEndpoint(CohortEndpoints.basicList, {}, {}, {}, 'GET', PlatformService);
  return data;
};

export const getCohortItem = async (payload: RequestByItemExtId) => {
  const data: CohortItemResponse = await fetchEndpoint(
    CohortEndpoints.cohortByItemExtId,
    { item_ext_id: payload.itemExtId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getReport = async (payload: DetectGetItemRequest) => {
  const data: ReportItemResponse = await fetchEndpoint(
    ReportEndpoints.insightByItemExtId,
    { item_ext_id: payload.itemExtId },
    { configId: payload.configId },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getReportRunData = async (path: string) => {
  const data: any = await fetchEndpoint(RunEndpoints.runResult, {}, { path }, {}, 'GET', PlatformService);
  return data;
};

export const getReportUsers = async (payload: GetReportUsersRequest) => {
  const data: ReportItemResponse = await fetchEndpoint(
    ReportEndpoints.getUsers,
    {},
    {},
    { ...payload },
    'POST',
    PlatformService,
  );
  return data;
};

/** Get the total count of Dashboard Summaries for a user */
export const getDashboardCount = async (request: GetDashboardCountRequest) => {
  const data: number = await fetchEndpoint(DashboardEndpoints.dashboardCount, {}, request, {}, 'GET', PlatformService);
  return data;
};

/** Get the list of Dashboard Summaries for a user */
export const getDashboardSummariesByUser = async (request: GetDashboardRequest) => {
  const data: Array<DashboardSummaryResponse> = await fetchEndpoint(
    DashboardEndpoints.createDashboard,
    {},
    request,
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getDashboard = async (payload: { itemExtId: string }) => {
  const data: DashboardSummaryResponse = await fetchEndpoint(
    DashboardEndpoints.dashboardByItemExtId,
    { item_ext_id: payload.itemExtId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getDefaultDashboard = async () => {
  const data: DefaultDashboardSummaryResponse = await fetchEndpoint(
    DashboardEndpoints.defaultDashboard,
    {},
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getSearchItems = async (request: GetSearchRequest) => {
  const data: SearchItem[] = await fetchEndpoint(SearchEndpoints.searchItems, {}, request, {}, 'GET', PlatformService);
  return data;
};

export const getSearchCreatorItems = async (request: GetSearchCreatorRequest) => {
  const data: BasicUserInfo[] = await fetchEndpoint(
    UserInfoEndpoints.filteredUsers,
    {},
    request,
    {},
    'GET',
    UserGovService,
  );
  return data;
};

export const getMyEvent = async () => {
  const data: MyEventItemResponse = await fetchEndpoint(EventEndpoints.eventByItem, {}, {}, {}, 'GET', PlatformService);
  return data;
};

export const getEvent = async (payload: DetectGetItemRequest) => {
  const data: EventItemResponse = await fetchEndpoint(
    EventEndpoints.eventByItemExtId,
    { item_ext_id: payload.itemExtId },
    { configId: payload.configId },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getEventProperties = async () => {
  const data = await fetchEndpoint(EventEndpoints.eventProperties, {}, {}, {}, 'GET', PlatformService);
  return data;
};

export const getAlertConfigByExtId = async (payload: AlertExtId) => {
  const data: AlertConfig = await fetchEndpoint(
    AlertEndpoints.byItemExtId,
    { item_ext_id: payload.alertItemExtId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getAlertConfigByItemId = async (payload: AlertItemId) => {
  const data: AlertConfig = await fetchEndpoint(
    AlertEndpoints.byItemId,
    { item_id: payload.alertItemId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getAlertSummaryByItemId = async (payload: AlertItemId) => {
  const data: ReportAlertSummary = await fetchEndpoint(
    AlertEndpoints.summaryByItemId,
    { item_id: payload.alertItemId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getAlertList = async (payload: GetUserAlertRequest) => {
  const data: ReportAlertSummary[] = await fetchEndpoint(
    AlertEndpoints.alertList,
    {},
    { ...payload },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getReportSummariesBulk = async (payload: { reportItemExtIds: string[] }) => {
  if (payload.reportItemExtIds.length < 1) return Promise.resolve([]);
  const data: ReportSummaryResponse[] = await fetchEndpoint(
    ReportEndpoints.bulk,
    {},
    { reports: payload.reportItemExtIds.join(',') },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getAlertListCount = async (payload: GetUserAlertCountRequest) => {
  const data: number = await fetchEndpoint(
    AlertEndpoints.alertListCount,
    {},
    { ...payload },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getAlertsForReport = async (payload: RequestByItemExtId) => {
  const data: ReportAlertSummary[] = await fetchEndpoint(
    AlertEndpoints.alertsForReport,
    {},
    { report_id: payload.itemExtId },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getAlertCountForReport = async (payload: RequestByItemExtId) => {
  if (payload.itemExtId.trim() === '') {
    return 0;
  }
  const data: number = await fetchEndpoint(
    AlertEndpoints.alertCountForReport,
    {},
    { report_id: payload.itemExtId },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getAnomaliesForReport = async (payload: GetAnomaliesForReportRequest) => {
  const data: AnomalySummary[] = await fetchEndpoint(
    AlertEndpoints.anomaliesForReport,
    {},
    { report_id: payload.reportItemExtId, offset: payload.offset, limit: payload.limit },
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const getAnomalyInfo = async (anomalyId: string) => {
  const data: AnomalySummary = await fetchEndpoint(
    AlertEndpoints.byAnomalyId,
    { anomaly_id: anomalyId },
    {},
    {},
    'GET',
    PlatformService,
  );
  return data;
};

/** Get the total count of Custom Events */
export const getCustomEventsCount = async (request: GetCustomEventsCountRequest) => {
  const data: number = await fetchEndpoint(CustomEventsEndpoints.count, {}, request, {}, 'GET', PlatformService);
  return data;
};

/** Get the list of Custom Events */
export const getCustomEvents = async (request: GetCustomEventsRequest) => {
  const data: Array<CustomEventDTO> = await fetchEndpoint(
    CustomEventsEndpoints.customEventsList,
    {},
    request,
    {},
    'GET',
    PlatformService,
  );
  return data;
};

/** Get list of all users in  a workspace */
// export const getAllUsersInWorkspace = async () => {
//   if (isDemoUrl()) return Promise.resolve([]);
//   const data: Array<BasicUserInfo> = await fetchEndpoint(UserInfoEndpoints.allUsers, {}, {}, {}, 'GET', UserGovService);
//   return data;
// };

/** Get list of all teams for a workspace */
export const getTeams = async (payload: GetListRequest) => {
  const data: Array<TeamInfoDetailed> = await fetchEndpoint(
    TeamEndpoints.base,
    {},
    { limit: payload.limit, offset: payload.offset },
    {},
    'GET',
    UserGovService,
  );
  return data;
};

/** Get Team Info */
export const getTeam = async (payload: { teamId: string }) => {
  const data: TeamInfoDetailed = await fetchEndpoint(
    TeamEndpoints.team,
    { team_id: payload.teamId },
    {},
    {},
    'GET',
    UserGovService,
  );
  return data;
};

/** Get List of users in a team */
export const getUsersInTeam = async (payload: GetUsersInTeamRequest) => {
  if (payload.teamId === '') return [];
  const data: Array<PerceptUserInfo> = await fetchEndpoint(
    TeamEndpoints.teamUsers,
    { team_id: payload.teamId },
    { limit: payload.limit, offset: payload.offset },
    {},
    'GET',
    UserGovService,
  );
  return data;
};

export const getUsersCountInTeam = async (payload: { teamId: string }) => {
  if (payload.teamId === '') return 0;
  const data: number = await fetchEndpoint(
    TeamEndpoints.teamUsersCount,
    { teamId: payload.teamId },
    {},
    {},
    'GET',
    UserGovService,
  );
  return data;
};

export const getUserRoles = async () => {
  const data: Array<string> = await fetchEndpoint(RoleEndpoints.getRoles, {}, {}, {}, 'GET', UserGovService);
  return data;
};

export const getUsersInWorkspace = async (payload: GetListRequest) => {
  const data: Array<UserDetail> = await fetchEndpoint(
    UserInfoEndpoints.getUsers,
    {},
    { ...payload },
    {},
    'GET',
    UserGovService,
  );
  return data;
};

export const getUsersCountInWorkspace = async () => {
  const data: number = await fetchEndpoint(UserInfoEndpoints.usersCount, {}, {}, {}, 'GET', UserGovService);
  return data;
};

export const getRecentlyViewed = async (request: GetRecentlyViewedRequest) => {
  const data: RecentlyViewedItemResponse[] = await fetchEndpoint(
    UserEndpoints.recentlyViewed,
    {},
    request,
    {},
    'GET',
    PlatformService,
  );
  return data;
};

export const createCohortFromReportUserView = async (payload: CreateReportUsersCohortRequest) => {
  const data: ReportItemResponse = await fetchEndpoint(
    CohortEndpoints.createUsersCohort,
    {},
    {},
    { ...payload },
    'POST',
    PlatformService,
  );
  return data;
};
