/* eslint-disable import/prefer-default-export */
import { CohortCreationMethod } from '@src/client/modules/cohorts/CreateCohortScreen';
import MyReportsScreen from '@src/client/modules/home/MyReports';
import React from 'react';

import { RotuesInfo } from './types';

export enum ScreenNames {
  DataGovernanceDashboard = 'DataGovernanceDashboard',
  HomeScreen = 'HomeScreen',
  InsightsScreen = 'InsightsScreen',
  CohortDashboard = 'CohortDashboard',
  DashboardScreen = 'DashboardScreen',
  DashboardList = 'DashboardList',
  FunnelsScreen = 'FunnelsScreen',
  CustomEventsDashboard = 'CustomEventsDashboard',
  EventsScreen = 'EventsScreen',
  LoginScreen = 'LoginScreen',
  WelcomeScreen = 'WelcomeScreen',
  ErrorBoundaryFallbackScreen = 'ErrorBoundaryFallbackScreen',
  EngageDashboard = 'EngageDashboard',
  CreateCampaign = 'CreateCampaign',
  CreateAlert = 'CreateAlert',
  AlertList = 'AlertsList',
  AlertsDashboard = 'AlertsDashboard',
  AlertAnalysisDashboard = 'AlertAnalysisDashboard',
  EventsInfoScreen = 'EventsInfoScreen',
  EventPropertiesScreen = 'EventPropertiesScreen',
  TeamsDashboard = 'TeamsDashboard',
  FlowsScreen = 'FlowsScreen',
  YourReportsScreen = 'YourReportsScreen',
  DefaultDashboardScreen = 'DefaultDashboardScreen',
  NotFoundScreen = 'NotFoundScreen',
  CreateCohort = 'CreateCohort',
  FlexicastDashboard = 'FlexicastDashboard',
  CreateFlexicast = 'CreateFlexicast',
  GlobalLimitsDashboard = 'GlobalLimitsDashboard',
  RetentionScreen = 'RetentionScreen',
  UserProfilesScreen = 'UserProfilesScreen',
  UserProfileEventDashboard = 'UserProfileEventDashboard',
  UnsubscribeScreen = 'UnsubscribeScreen',
  HotSpotsDashboardScreen = 'HotSpotsDashboardScreen',
  HotSpotsHeatzoneScreen = 'HotSpotsHeatzoneScreen',
  ExperimentDashboard = 'ExperimentDashboard',
  CreateExperiment = 'CreateExperiment',
  FeatureFlagDashboard = 'FeatureFlagDashboard',
  CreateFeatureFlag = 'CreateFeatureFlag',
  SdkIntegrationScreen = 'SdkIntegrationScreen',
  WalletScreen = 'WalletScreen',
  CreateTemplates = 'CreateTemplates',
}

const InsightsScreen = React.lazy(() => import('@src/client/modules/insights/InsightsScreen'));
const CohortDashboard = React.lazy(() => import('@src/client/modules/cohorts/CohortListPage'));
const CreateCohortScreen = React.lazy(() => import('@src/client/modules/cohorts/CreateCohortScreen'));
const DashboardScreen = React.lazy(() => import('@src/client/modules/dashboard'));
const FunnelsScreen = React.lazy(() => import('@src/client/modules/funnels/FunnelsScreen'));
const CustomEventsDashboard = React.lazy(() => import('@src/client/modules/custom-events/CustomEventsPage'));
const GlobalLimitsDashboard = React.lazy(() => import('@src/client/modules/global-limits/GlobalLimitsDashboard'));
const EventsScreen = React.lazy(() => import('@src/client/modules/events'));
const EngageDashboard = React.lazy(() => import('@src/client/modules/engage/campaign/CampaignListPage'));
const CreateCampaignScreen = React.lazy(() => import('@src/client/modules/engage/campaign/CampaignDetailsPage'));
const AlertsDashboard = React.lazy(() => import('@src/client/modules/alerts/AlertListPage'));
const UamDashboard = React.lazy(() => import('@src/client/modules/uam/UamDashboard'));
const AlertAnalysisDashboard = React.lazy(() => import('@src/client/modules/alerts/AlertAnalysisDashboard'));
const FlowsScreen = React.lazy(() => import('@src/client/modules/flows/FlowsScreen'));
const FlexicastDashboard = React.lazy(() => import('@src/client/modules/engage/flexicast/FlexicastListPage'));
const CreateFlexicastScreen = React.lazy(() => import('@src/client/modules/engage/flexicast/FlexicastDetailsPage'));
const RetentionScreen = React.lazy(() => import('@src/client/modules/retention/RetentionScreen'));
const UserProfilesScreen = React.lazy(() => import('@src/client/modules/user-profiles/UserProfilesScreen'));
const UserProfileEventDashboard = React.lazy(
  () => import('@src/client/modules/user-profiles/UserProfileEventDashboard'),
);
const DataGovernanceDashboard = React.lazy(() => import('@src/client/modules/data-governance/DataGovDashboard'));
const HotspotDasboardScreen = React.lazy(() => import('@src/client/modules/hotspots/HotspotDashboard'));
const ExperimentDashboard = React.lazy(() => import('@src/client/modules/experiments/ExperimentListPage'));
const CreateExperimentScreen = React.lazy(() => import('@src/client/modules/experiments/ExperimentDetailsPage'));
const FeatureFlagDashboard = React.lazy(() => import('@src/client/modules/feature-flags/FeatureFlagListPage'));
const CreateFeatureFlagScreen = React.lazy(() => import('@src/client/modules/feature-flags/FeatureFlagDetailsPage'));
const SdkIntegrationScreen = React.lazy(() => import('@src/client/modules/sdk-integration/SdkIntegrationScreen'));
const WalletScreen = React.lazy(() => import('@src/client/modules/wallet/WalletScreen'));
const DefaultDashboardScreen = React.lazy(() => import('@src/client/modules/dashboard/DefaultDashboard'));
const EmailTemplateGenerator = React.lazy(
  () => import('@src/client/modules/email-template-editor/EmailTemplateGenerator'),
);

export const ALL_ROUTES: RotuesInfo[] = [
  {
    title: 'Insights',
    path: 'insights',
    screenName: ScreenNames.InsightsScreen,
    component: <InsightsScreen />,
    selector: 'insights_read',
    description:
      'Analyze the behavior of actions or events\n' +
      'over time through a visual representation\n' +
      'and detailed breakdown',
    subRoutes: [
      {
        title: 'Insight',
        path: ':itemExtId',
        component: <InsightsScreen />,
        screenName: ScreenNames.InsightsScreen,
        description:
          'Analyze the behavior of actions or events\n' +
          'over time through a visual representation\n' +
          'and detailed breakdown',
        selector: 'insights_read',
      },
      {
        title: 'Anomaly Analysis Dashboard',
        path: ':itemExtId/anomaly/:anomalyId/dashboard',
        component: <AlertAnalysisDashboard />,
        screenName: ScreenNames.AlertAnalysisDashboard,
        description: 'Anomaly insight',
        selector: 'insights_read',
      },
    ],
  },
  {
    title: 'Funnels',
    path: 'funnels',
    screenName: ScreenNames.FunnelsScreen,
    component: <FunnelsScreen />,
    selector: 'funnels_read',
    description: 'Analyze user journey and drop-off rates to determine action sequence completion',
    subRoutes: [
      {
        title: 'Funnel',
        description: 'Analyze user journey and drop-off rates to determine action sequence completion',
        path: ':itemExtId',
        component: <FunnelsScreen />,
        screenName: ScreenNames.FunnelsScreen,
        selector: 'funnels_read',
      },
      {
        title: 'Anomaly Analysis Dashboard',
        path: ':itemExtId/anomaly/:anomalyId/dashboard',
        component: <AlertAnalysisDashboard />,
        screenName: ScreenNames.AlertAnalysisDashboard,
        description: 'Anomaly funnel',
        selector: 'funnels_read',
      },
    ],
  },
  {
    title: 'Cohorts',
    path: 'cohort',
    screenName: ScreenNames.CohortDashboard,
    component: <CohortDashboard />,
    selector: 'cohorts_read',
    description: 'Group individuals based on shared characteristics.',
    subRoutes: [
      {
        title: 'Cohorts',
        description: 'List of cohorts',
        path: 'list',
        component: <CohortDashboard />,
        screenName: ScreenNames.CohortDashboard,
        selector: 'cohorts_read',
      },
      {
        title: 'Update Cohort',
        path: ':itemExtId',
        component: <CreateCohortScreen creationMethod={undefined} />,
        screenName: ScreenNames.CreateCohort,
        description: 'Update cohort properties.',
        selector: 'query_run',
      },
      {
        title: 'Create Cohort',
        path: 'create',
        component: <CreateCohortScreen creationMethod={CohortCreationMethod.FilterSelection} />,
        screenName: ScreenNames.CreateCohort,
        description: 'Create cohorts of users based on shared characteristics.',
        selector: 'query_run',
      },
      {
        title: 'Create Cohort Using File Upload',
        path: 'create/upload-csv',
        component: <CreateCohortScreen creationMethod={CohortCreationMethod.UploadCSV} />,
        screenName: ScreenNames.CreateCohort,
        description: 'Create cohorts of users using csv file upload.',
        selector: 'query_run',
      },
      {
        title: 'Create Cohort Using User Selection',
        path: 'create/select-users',
        component: <CreateCohortScreen creationMethod={CohortCreationMethod.UserSelection} />,
        screenName: ScreenNames.CreateCohort,
        description: 'Create cohorts of users using user selection.',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Custom Events',
    path: 'custom-events',
    screenName: ScreenNames.CustomEventsDashboard,
    component: <CustomEventsDashboard />,
    selector: 'query_run',
    description: 'Group similar events for easy analysis',
    subRoutes: [
      {
        title: 'Custom Events',
        path: 'list',
        component: <CustomEventsDashboard />,
        screenName: ScreenNames.CustomEventsDashboard,
        description: 'Group similar events for easy analysis',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Engage Settings',
    path: 'engage-settings',
    screenName: ScreenNames.GlobalLimitsDashboard,
    component: <GlobalLimitsDashboard />,
    selector: 'query_run',
    description: 'Set Global Communication Limits for Engage',
    subRoutes: [
      {
        title: 'Engage Settings',
        path: '',
        component: <GlobalLimitsDashboard />,
        screenName: ScreenNames.GlobalLimitsDashboard,
        description: 'Set Global Communication Limits for Engage',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Sdk Integrations',
    path: 'sdk-integrations',
    screenName: ScreenNames.SdkIntegrationScreen,
    component: <SdkIntegrationScreen />,
    selector: 'query_run',
    description: 'Get info on successfully integrated sdks',
    subRoutes: [
      {
        title: 'Sdk Integrations',
        path: '',
        screenName: ScreenNames.SdkIntegrationScreen,
        component: <SdkIntegrationScreen />,
        selector: 'query_run',
        description: 'Get info on successfully integrated sdks',
      },
    ],
  },
  {
    title: 'Dashboards',
    path: 'dashboard',
    screenName: ScreenNames.DashboardScreen,
    component: <DashboardScreen />,
    selector: 'dashboards_read',
    description: 'Visualize data, standardize reporting and make decisions with dashboards',
    subRoutes: [
      {
        title: 'Dashboards',
        description: 'Visualize data, standardize reporting and make decisions with dashboards',
        path: ':itemExtId',
        component: <DashboardScreen />,
        screenName: ScreenNames.DashboardScreen,
        selector: 'dashboards_read',
      },
    ],
  },
  {
    title: 'Events',
    path: 'events',
    screenName: ScreenNames.EventsScreen,
    component: <EventsScreen />,
    selector: 'events_read',
    description: 'Easily group users action as events for efficient analysis and tracking',
    subRoutes: [
      {
        title: 'Event',
        path: ':itemExtId',
        component: <EventsScreen />,
        screenName: ScreenNames.EventsScreen,
        description: 'Easily group users action as events for efficient analysis and tracking',
        selector: 'events_read',
      },
    ],
  },
  {
    title: 'Retention',
    path: 'retention',
    screenName: ScreenNames.RetentionScreen,
    component: <RetentionScreen />,
    selector: 'events_read',
    description: 'Track user retention and monitor trends',
    subRoutes: [
      {
        title: 'Retention',
        path: ':itemExtId',
        component: <RetentionScreen />,
        screenName: ScreenNames.RetentionScreen,
        description: 'Track user retension and monitor trends',
        selector: 'events_read',
      },
    ],
  },
  {
    title: 'Engage',
    path: 'engage',
    screenName: ScreenNames.EngageDashboard,
    component: <EngageDashboard />,
    selector: 'query_run',
    description: 'Create campaigns and connect with your users',
    subRoutes: [
      {
        title: 'Engage',
        description: 'Engage campaigns list',
        path: 'list',
        component: <EngageDashboard />,
        screenName: ScreenNames.EngageDashboard,
        selector: 'query_run',
      },
      {
        title: 'Create Campaign',
        path: ':itemExtId',
        component: <CreateCampaignScreen />,
        screenName: ScreenNames.CreateCampaign,
        description: 'Create campaigns and connect with your users',
        selector: 'query_run',
      },
      {
        title: 'Create Campaign',
        path: 'create',
        component: <CreateCampaignScreen />,
        screenName: ScreenNames.CreateCampaign,
        description: 'Create campaigns and connect with your users',
        selector: 'query_run',
      },
      {
        title: 'Create Template',
        path: 'create-template',
        component: <EmailTemplateGenerator />,
        screenName: ScreenNames.CreateCampaign,
        description: 'Create email templates',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'FlexiCast',
    path: 'flexicast',
    screenName: ScreenNames.FlexicastDashboard,
    component: <FlexicastDashboard />,
    selector: 'query_run',
    description: 'Create targeted personalized communications to connect with your users',
    subRoutes: [
      {
        title: 'FlexiCast',
        description: 'Flexicast list',
        path: 'list',
        component: <FlexicastDashboard />,
        screenName: ScreenNames.FlexicastDashboard,
        selector: 'query_run',
      },
      {
        title: 'Create FlexiCast',
        path: ':itemExtId',
        component: <CreateFlexicastScreen />,
        screenName: ScreenNames.CreateFlexicast,
        description: 'Create flexicast and connect with your users',
        selector: 'query_run',
      },
      {
        title: 'Create FlexiCast',
        path: 'create',
        component: <CreateFlexicastScreen />,
        screenName: ScreenNames.CreateFlexicast,
        description: 'Create flexicast and connect with your users',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Alerts',
    path: 'alert',
    screenName: ScreenNames.AlertList,
    component: <AlertsDashboard />,
    selector: 'query_run',
    description: 'View configured alerts',
    subRoutes: [
      {
        title: 'Alerts',
        description: 'View configured alerts',
        path: 'list',
        component: <AlertsDashboard />,
        screenName: ScreenNames.AlertList,
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Users and Teams',
    path: 'settings/manage-users',
    screenName: ScreenNames.TeamsDashboard,
    component: <UamDashboard />,
    selector: 'workspaces_read', // TODO: Update this to settings_manage
    description: 'Manage Users and Teams',
    subRoutes: [
      {
        title: 'Teams',
        path: 'teams/list',
        component: <UamDashboard />,
        screenName: ScreenNames.TeamsDashboard,
        description: 'Users and Teams list',
        selector: 'workspaces_read',
      },
      {
        title: 'Users',
        path: 'users/list',
        component: <UamDashboard />,
        screenName: ScreenNames.TeamsDashboard,
        description: 'Users and Teams list',
        selector: 'workspaces_read',
      },
    ],
  },
  {
    title: 'Data Governance',
    path: 'settings/data-governance',
    screenName: ScreenNames.DataGovernanceDashboard,
    component: <DataGovernanceDashboard />,
    selector: 'workspaces_manage',
    description: 'Manage data governance policies',
  },
  {
    title: 'Flows',
    path: 'flows',
    screenName: ScreenNames.FlowsScreen,
    component: <FlowsScreen />,
    selector: 'funnels_read',
    description: 'View entire user journey between given source and destination',
    subRoutes: [
      {
        title: 'Flows',
        path: ':itemExtId',
        component: <FlowsScreen />,
        screenName: ScreenNames.FlowsScreen,
        selector: 'funnels_read',
        description: 'View entire user journey between given source and destination',
      },
    ],
  },
  {
    title: 'Your Reports',
    path: 'my-reports',
    screenName: ScreenNames.YourReportsScreen,
    component: <MyReportsScreen />,
    selector: 'insights_read',
    description: 'List of all your report',
  },
  {
    title: 'Default Dashboard',
    path: 'default-dashboard',
    screenName: ScreenNames.DefaultDashboardScreen,
    component: <DefaultDashboardScreen />,
    selector: 'insights_read',
    description: 'Smart reports screen',
  },
  {
    title: 'Wallet',
    path: 'wallet',
    screenName: ScreenNames.WalletScreen,
    component: <WalletScreen />,
    selector: 'insights_read',
    description: 'Wallet transactions',
  },
  {
    title: 'User Profiles',
    path: 'user-profiles',
    screenName: ScreenNames.UserProfilesScreen,
    component: <UserProfilesScreen />,
    selector: 'workspaces_read',
    description: 'View all user profiles',
    subRoutes: [
      {
        title: 'User Profiles',
        description: 'View all user profiles',
        path: 'list',
        component: <UserProfilesScreen />,
        screenName: ScreenNames.UserProfilesScreen,
        selector: 'workspaces_read',
      },
      {
        title: 'User Profile',
        path: ':userId',
        component: <UserProfileEventDashboard />,
        screenName: ScreenNames.UserProfileEventDashboard,
        description: 'View individual user profile with list of events',
        selector: 'workspaces_read',
      },
    ],
  },
  {
    title: 'HeatMaps',
    path: 'heatmaps',
    screenName: ScreenNames.HotSpotsDashboardScreen,
    component: <HotspotDasboardScreen />,
    selector: 'insights_read',
    description: 'View hotspot details of your website',
    subRoutes: [
      {
        title: 'Dashboard',
        path: 'dashboard',
        component: <HotspotDasboardScreen />,
        screenName: ScreenNames.HotSpotsHeatzoneScreen,
        selector: 'insights_read',
        description: 'View your page heatzones',
      },
    ],
  },
  {
    title: 'Experiment',
    path: 'experiment',
    screenName: ScreenNames.ExperimentDashboard,
    component: <ExperimentDashboard />,
    selector: 'query_run',
    description: 'Manage and analyze experiments to optimize user engagement',
    subRoutes: [
      {
        title: 'Experiment',
        description: 'Experiment list',
        path: 'list',
        component: <ExperimentDashboard />,
        screenName: ScreenNames.ExperimentDashboard,
        selector: 'query_run',
      },
      {
        title: 'Create Experiment',
        path: ':experimentId',
        component: <CreateExperimentScreen />,
        screenName: ScreenNames.CreateExperiment,
        description: 'Create and configure a new experiment',
        selector: 'query_run',
      },
      {
        title: 'Create Experiment',
        path: 'create',
        component: <CreateExperimentScreen />,
        screenName: ScreenNames.CreateExperiment,
        description: 'Create and configure a new experiment',
        selector: 'query_run',
      },
    ],
  },
  {
    title: 'Feature Flag',
    path: 'feature-flag',
    screenName: ScreenNames.FeatureFlagDashboard,
    component: <FeatureFlagDashboard />,
    selector: 'query_run',
    description: 'Manage and analyze feature flags to optimize user engagement',
    subRoutes: [
      {
        title: 'Feature Flag',
        description: 'Feature flag list',
        path: 'list',
        component: <FeatureFlagDashboard />,
        screenName: ScreenNames.FeatureFlagDashboard,
        selector: 'query_run',
      },
      {
        title: 'Create Feature Flag',
        path: ':featureFlagId',
        component: <CreateFeatureFlagScreen />,
        screenName: ScreenNames.CreateFeatureFlag,
        description: 'Create and configure a new feature flag',
        selector: 'query_run',
      },
      {
        title: 'Create Feature Flag',
        path: 'create',
        component: <CreateFeatureFlagScreen />,
        screenName: ScreenNames.CreateFeatureFlag,
        description: 'Create and configure a new feature flag',
        selector: 'query_run',
      },
    ],
  },
];
