import LayoutV2 from '@src/client/components/layout';
import { useCheckIfSdkIntegratedForOnboardingFlowClient } from '@src/client/helpers/reports/hooks';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { ScreenNames } from '@src/client/routes/data';
import { PageLoader } from '@src/client/ui-library/loaders';
import { Navigate } from 'react-router-dom';

import HomeLeftNavigationPanel from './components/HomeLeftNavigationPanel';
import HomeRightPanel from './components/HomeRightPanel';
import ReportsView from './components/ReportsView';

export default function HomeScreen() {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const { isSdkIntegrated, isFetching } = useCheckIfSdkIntegratedForOnboardingFlowClient();

  if (isFetching) return <PageLoader />;

  if (!isSdkIntegrated)
    return (
      <Navigate
        to={{
          pathname: getLinkWithWorkspace('sdk-integrations'),
        }}
      />
    );

  return (
    <LayoutV2 screenName={ScreenNames.HomeScreen}>
      <div className="flex relative w-full">
        <HomeLeftNavigationPanel />
        <div className="flex-1 py-6 px-4">
          <ReportsView currentUserOnly={false} />
        </div>
        <HomeRightPanel hideCreatedBy={false} />
      </div>
    </LayoutV2>
  );
}
