import Lottie from 'lottie-react';

import barAnimation from '../lottie-files/bar-loader.json';
import spinAnimation from '../lottie-files/spin-loader.json';

export function SpinLoader({ size = 'regular', relative = false }: { size?: 'small' | 'regular'; relative?: boolean }) {
  return (
    <Lottie
      animationData={spinAnimation}
      loop
      className={`${size === 'regular' ? 'h-12' : 'h-8'} ${
        relative ? '' : 'absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4'
      }`}
    />
  );
}

export function BarLoader() {
  return (
    <Lottie
      animationData={barAnimation}
      loop
      className="h-24 absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4"
    />
  );
}

export function PageLoader() {
  return (
    <div className="flex h-screen bg-background">
      <div className="h-navbar bg-neutral w-full" />
      <div className="pt-[60px] bg-background text-foreground min-h-layout-v2 box-border">
        <SpinLoader />
      </div>
    </div>
  );
}

export function AnimatedRadarPing() {
  return (
    <div className="relative flex h-3 w-3">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-dark dark:bg-primary-light opacity-75" />
      <span className="relative inline-flex rounded-full h-3 w-3 bg-primary" />
    </div>
  );
}
