/* Common */

import { FormattedFiltersForAPI } from '@src/client/helpers/reports/types';
import {
  AlertRule,
  AlertSubscription,
  AlertSupportedFrequencies,
  AlertSupportedSeverities,
  AlertSupportedStatuses,
  AlertTypes,
} from '@src/client/modules/alerts/types';
import { CohortType } from '@src/client/modules/cohorts/types';

import { PlatformItemType } from './request';

export type GridItemLayout = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  reportType: ReportType;
};

export enum ReportType {
  INSIGHT = 'INSIGHT',
  FUNNEL = 'FUNNEL',
  FLOW = 'FLOW',
  RETENTION = 'RETENTION',
}

export enum EngageType {
  CAMPAIGN = 'CAMPAIGN',
  FLEXICAST = 'FLEXICAST',
  TEMPLATE = 'TEMPLATE',
}

export enum UserType {
  COHORTS = 'COHORTS',
  PROFILES = 'PROFILES',
}

export enum DimensionType {
  EVENT = 'EVENT',
  COHORT = 'COHORT',
  CUSTOM_EVENT = 'CUSTOM_EVENT',
  PROPERTY = 'PROPERTY',
  TIMESTAMP = 'TIMESTAMP', // NOTE: @manish @prakul check if this is even supported. added tim e dimension below
  VIRTUAL_EVENT = 'VIRTUAL_EVENT',
  EVENT_GROUP = 'EVENT_GROUP',
  TIME = 'TIME',
}
/* DP: User Info */

export enum GroupByType {
  FREQUENCY = 'frequency',
}

export enum PropertyDataTypes {
  TIMESTAMP = 'TIMESTAMP',
  VARCHAR = 'VARCHAR',
}

export enum PluralSupportedTimeUnits {
  DAYS = 'days',
  MONTHS = 'months',
  WEEKS = 'weeks',
  SESSIONS = 'sessions',
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
}

export enum SingularSupportedTimeUnits {
  DAY = 'day',
  MONTH = 'month',
  WEEK = 'week',
  SESSION = 'session',
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
}

export interface BaseApiResponse {
  success: boolean;
  data: unknown;
  error: null | Error;
  traceId?: number;
}
export interface LoginWithAuthCodeResponse {
  id_token: string;
  refresh_token: string;
  access_token: string;
  expires_in_seconds: number;
  scope: string;
  token_type: string;
  first_time_login: boolean;
  user: LoginResponseUser;
}

export interface LoginWithIdTokenResponse {
  id_token: string;
  expires_at: string;
  first_time_login: boolean;
}

export interface LoginResponseUser {
  name: string | null;
  email: string;
  profile_url: string | null;
}

export interface RunStatusResponse {
  runId: string;
  itemId: string;
  configId: string;
  projectId: string;
  tenantId: string;
  status: string;
  constructedQueries?: string;
  outputPath?: string;
  updatedAt?: number;
  errorMsg?: string;
}

export interface CreateItemResponse {
  itemId: string;
  itemExternalId: string;
  configId: string;
  runId: string;
  projectId: string;
  tenantId: string;
  traceId?: number; // used by UI internally to identify last request. Not coming from backend right now. UI is injecting it in received response whenever required based on what value was sent in request.
}

export interface DuplicateItemResponse {
  itemExternalId: string;
  configId: string;
  tenantId: string;
  workspaceId: string;
}

export interface LlmCreateReportResponse {
  report_url: string;
  request_id: string;
  traceId?: number; // used by UI internally to identify last request. Not coming from backend right now. UI is injecting it in received response whenever required based on what value was sent in request.
}

export interface CreateCohortResponse {
  itemId: string;
  itemExternalId: string;
  configId: string;
  projectId: string;
  tenantId: string;
}

export interface ReportSummaryResponse {
  itemId: string;
  itemExternalId: string;
  configId: string;
  name: string;
  description: string;
  status: string;
  itemType: PlatformItemType;
  reportType: ReportType;
  createdAt: number;
  createdBy: string;
  projectId: string;
}

export interface ReportCountResponse {
  count: number;
}

export interface RecentlyAccessedItems {
  createdBy: string;
  itemId: string;
  itemExternalId: string;
  name: string;
  itemType: PlatformItemType;
  lastOpenedAt: number;
  reportType: ReportType;
}

export interface CohortSummaryResponse {
  itemId: string;
  itemExternalId: string;
  configId: string;
  name: string;
  description: string;
  status: string;
  createdAt: number;
  createdBy: string;
}

export interface CohortBasicItem {
  itemId: string;
  itemExternalId: string;
  name: string;
  description: string;
  itemType: PlatformItemType;
  cohortType: CohortType;
}

export interface ItemCountResponse {
  count: number;
}

export interface ReportItemResponse {
  itemId: string;
  configId: string;
  itemExternalId: string;
  name: string;
  description: string;
  status: string;
  reportType: ReportType;
  createdAt: number;
  createdBy: string;
  runId?: string;
  runStatus?: string;
  constructedQueries?: string;
  outputPath?: string;
  params: { [key: string]: any };
  saved: boolean;
}

export interface CohortItemResponse {
  itemId: string;
  configId: string;
  itemExternalId: string;
  name: string;
  description: string;
  status: string;
  createdAt: number;
  createdBy: string;
  itemType: PlatformItemType;
  params: { [key: string]: any };
}

export interface Workspace {
  // configId: string;
  id: string;
  name: string;
  tenantId: string;
  logoUrl?: string;
  // timezone: string;
}

export interface UserInfo {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  profileUrl: string;
  privilegeLevel: number;
  roleName: string;
  activeTenantId: string;
  activeWorkspaceId: string;
  // featureList: Array<string>;
  accessScopes: Array<string>;
  workspaces: Array<Workspace>;
  organization?: string;
}

export interface UserDetail {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  roleName: string;
  profileUrl: string;
}

export interface CompleteUserInfo {
  userId: string;
  userEmail: string;
  firstName: string;
  lastName: string;
  secondaryEmail: string;
  contactNo: string;
  userLastLoginAt: string;
  userActive: boolean;
  userDeleted: boolean;
  userDeletedBy: string;
  userDeletedAt: string;
  userCreatedAt: string;
  userCreatedBy: string;
  userUpdatedAt: string;
  currentLoginTenant: string;
  availableAuthMethods: Array<string>;
  associatedClientIds: Array<string>;
  associatedTenantIds: Array<string>;
}

export interface UserAccessScopes {
  userId: string;
  userEmail: string;
  tenantId: string;
  tenantActive: boolean;
  tenantDeleted: boolean;
  tenantCreatedAt: string;
  tenantDeletedAt: string;
  scopeIds: Array<string>;
  scopeNames: Array<string>;
  scopeCodes: Array<string>;
  productIds: Array<string>;
  productNames: Array<string>;
  productCodes: Array<string>;
}

export interface Tag {
  versionId: string;
  tagsId: string;
  itemId: string;
  itemType: string;
  createdAt: string;
  createdBy: string;
  tagExternalId: string;
  tagName: string;
}

export interface FormulaDTO {
  name: string;
  expression: string;
}
export interface BreakdownDTO {
  resource_type: string;
  property: string;
}
export interface DimensionDetailed {
  column: string;
  dataType: string;
  cached: boolean;
}

export interface DeleteItemResponse {
  itemExternalId: string;
  success: boolean;
}

export interface GroupByObject {
  property: string | string[];
  resource_type: DimensionType;
  group_by_type?: GroupByType;
  bucket_size?: number;
  include_null_users?: boolean;
}

export interface EventsAndCohorts {
  label: string;
  value: string;
}

export interface PropertiesAndCohorts {
  label: string;
  value: string;
  type: DimensionType;
  propertyCached: boolean;
}

/* Dashboard interfaces start */

export interface BaseDashboardSummaryResponse {
  itemId: string;
  itemExternalId: string;
  configId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
  createdBy: string;
  projectId: string;
  isDefault: boolean;
}
export interface DashboardSummaryResponse extends BaseDashboardSummaryResponse {
  layout: DashboardLayout[];
}

export interface DefaultDashboardSummaryResponse extends BaseDashboardSummaryResponse {
  layout: {
    reports: Array<{
      itemExternalId: string;
      reportType: ReportType;
      sequenceId: number;
    }>;
  };
}

export interface DashboardLayout {
  itemExternalId: string;
  xAxis: number;
  yAxis: number;
  height: number;
  width: number;
  reportType: ReportType;
}

export interface DeleteDashboardResponse {
  itemExternalId: string;
  success: boolean;
}
export interface DashboardItem {
  itemId: string;
  itemExternalId: string;
  configId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
  createdBy: string;
  projectId: string;
  gridItems: GridItemLayout[];
}

export interface CreateDashboardResponse {
  itemId: string;
  itemExternalId: string;
  configId: string;
  projectId: string;
  tenantId: string;
}

/* Dashboard interfaces end */

/* Search interfaces start */
export interface SearchItem {
  itemId: string;
  itemExternalId: string;
  name: string;
  itemType: PlatformItemType;
  reportType: ReportType | null;
  createdAt: number;
  createdBy: string;
}

export interface SearchCreatorItem {
  userId: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  profileUrl: string | null;
}

/* Search interfaces end */

/* Event interfaces start */
export interface EventItemResponse {
  itemId: string;
  configId: string;
  itemExternalId: string;
  status: string;
  workspaceId: string;
  tenantId: string;
  reportType: ReportType;
  createdAt: number;
  createdBy: string;
  runId?: string;
  runStatus?: string;
  constructedQueries?: string;
  outputPath?: string;
  params: { [key: string]: any };
}

export interface CreateEventResponse {
  itemId: string;
  itemExternalId: string;
  configId: string;
  runId: string;
  workspaceId: string;
  tenantId: string;
  traceId?: number; // used by UI internally to identify last request. Not coming from backend right now. UI is injecting it in received response whenever required based on what value was sent in request.
}

export interface MyEventItemResponse {
  itemExternalId: string;
  configId: string;
}
/* Event interfaces end */

export interface ReportAlertSummary {
  itemId: string;
  itemExternalId: string;
  configId: string;
  tenantId: string;
  workspaceId: string;
  name: string;
  description: string;
  reportItemExternalId: string;
  reportType: ReportType;
  reportName: string;
  createdBy: string;
  createdAt: number;
  alertStatus: string;
  severity: AlertSupportedSeverities;
}

export interface AlertConfig {
  itemId: string;
  configId: string;
  tenantId: string;
  workspaceId: string;
  alertType: AlertTypes;
  reportId: string;
  name: string;
  description?: string;
  rules: AlertRule;
  frequency: AlertSupportedFrequencies;
  subscriptions: AlertSubscription[];
  createdBy: string;
  alertStatus: AlertSupportedStatuses;
  isAnalysisDashboard: boolean;
  dashboardReportList?: string[];
}

export enum ChangeDirection {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

export interface AnomalySummary {
  anomalyId: string;
  alertId: string;
  anomalyValue: number;
  changeDirection: ChangeDirection;
  thresholdValue: string;
  createdAt: number;
  outputPath: string;
  alertName: string;
  alertSeverity: AlertSupportedSeverities;
  alertFrequency: AlertSupportedFrequencies;
}

/* Custom Event interfaces start */

export enum CustomEventType {
  CUSTOM_EVENT = 'CUSTOM_EVENT',
  CUSTOM_PROPERTY = 'CUSTOM_PROPERTY',
  VIRTUAL_EVENT = 'VIRTUAL_EVENT',
  EVENT_GROUP = 'EVENT_GROUP',
}

export enum CustomEventStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export type CustomEventParams = {
  events: Array<{
    alias: string;
    name: string;
    filters: FormattedFiltersForAPI;
  }>;
};

export interface CustomEventDTO {
  itemId: string;
  configId: string;
  itemExternalId: string;
  name: string;
  description: string;
  type: CustomEventType;
  workspaceId: string;
  tenantId: string;
  status: CustomEventStatus;
  createdAt: number;
  createdBy: string;
  params: CustomEventParams;
}

/* Custom Event interfaces end */

export interface RecentlyViewedItemResponse {
  createdBy: string;
  itemExternalId: string;
  itemId: string;
  itemType: PlatformItemType;
  lastOpenedAt: number;
  name: string;
  description?: string;
  reportType: ReportType | null;
}

export interface FetchEventsResponse extends BaseApiResponse {
  data: Array<Record<string, unknown>>;
}

export interface ReportRunDataBaseRes {
  original: unknown;
  compare: unknown;
  report_type: ReportType;
  series_type: string;
}

export type GetReportUsersResData = {
  event: string;
  max_time: string;
  pi_city: string;
  user_id: string;
};
export interface GetReportUsersRes extends ReportRunDataBaseRes {
  original: GetReportUsersResData[];
}
