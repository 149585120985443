import { ChevronDownIcon, LockClosedIcon } from '@radix-ui/react-icons';
import {
  EngageTypeDisplayInfo,
  EngageTypes,
  ReportTypeDisplyInfo,
  ReportTypes,
  UserTypeDisplayInfo,
  UserTypes,
} from '@src/client/helpers/constants';
import {
  useCheckIfSdkIntegratedForOnboardingFlowClient,
  useIsOnboardingCreatedWorkspace,
} from '@src/client/helpers/reports/hooks';
import {
  useIsDemoWorkspace,
  useIsExperimentEnabled,
  useIsFeatureFlagEnabled,
  useIsHeatMapEnabled,
  useNavigationLinkWithWorkspace,
} from '@src/client/hooks';
import { UserType } from '@src/client/lib/api/types/response';
import { integratedSdks } from '@src/client/modules/sdk-integration/atoms';
import { userInfoState } from '@src/client/recoil/atoms';
import { NavMenu } from '@src/client/ui-library/navigation-menu';
import { navigationMenuTriggerStyle } from '@src/client/ui-library/navigation-menu/NavbarPrimitives';
import { cn } from '@src/client/ui-library/utils';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../../ui-library/dropdown';
import { Tooltip } from '../../ui-library/tooltip';
import { ELEVATED_ACCESS_ROLES, MARKETING_ANALYST, USER_PROFILE_NOT_ALLOWED_TENANT } from './constants';

export default function NavbarMenu() {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const [userInfo] = useRecoilState(userInfoState);
  const isDemoWorkspace = useIsDemoWorkspace();
  const isExperimentEnabled = useIsExperimentEnabled();
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled();

  const isHeatMapEnabled = useIsHeatMapEnabled();
  const { isSdkIntegrated, isFetching: isSdkInfoLoading } = useCheckIfSdkIntegratedForOnboardingFlowClient();
  const sdks = useRecoilValue(integratedSdks);
  const isWorkspaceCreatedViaOnboardingFlow = useIsOnboardingCreatedWorkspace();

  const canShowExperiments =
    isExperimentEnabled ||
    (isWorkspaceCreatedViaOnboardingFlow && sdks.some((sdk) => sdk.sdkType === 'Web' || sdk.sdkType === 'Flutter'));

  const navItems = useMemo(
    () => [
      {
        key: '1',
        item: (
          <DropdownMenu>
            <DropdownMenuTrigger
              className={`${
                isSdkIntegrated ? '' : 'opacity-70'
              } text-white dark:text-white hover:text-white dark:hover:text-white hover:rounded-md-2 hover:bg-neutral-dark dark:hover:bg-neutral-dark data-[active]:text-white data-[active]:bg-neutral-dark/50 dark:data-[state=open]:bg-neutral-dark/50 data-[state=open]:bg-neutral-dark/50 data-[state=open]:text-white`}
            >
              <span className="mr-1">Reports</span>
              <ChevronDownIcon className="h-3 w-3 text-gray-300" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="ml-16 w-50 max-h-[800px] overflow-y-scroll">
              <ul className="flex flex-col space-y-2.5 p-3">
                {ReportTypes.map((reportType) => {
                  const data = ReportTypeDisplyInfo[reportType];
                  return (
                    <Link to={getLinkWithWorkspace(data.path)} key={data.path} reloadDocument>
                      <div className="p-3.5 rounded-lg w-[250px]" style={{ background: data.color }}>
                        <div className="flex items-center mb-3">
                          <img src={data.imageSrc} className="w-8 h-8 mr-2" alt={`${data.title} icon`} />
                          <p className="text-md font-semibold text-gray-950">{data.title}</p>
                        </div>
                        <p className="text-xs font-normal text-gray-700 mb-4">{data.description}</p>
                        <p className="text-primary-dark text-sm font-semibold">{`+ Create ${data.title}`}</p>
                      </div>
                    </Link>
                  );
                })}
              </ul>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
      {
        key: '2',
        item:
          !isDemoWorkspace && userInfo && !USER_PROFILE_NOT_ALLOWED_TENANT.includes(userInfo.activeTenantId) ? (
            <DropdownMenu>
              <DropdownMenuTrigger
                className={`${
                  isSdkIntegrated ? '' : 'opacity-70'
                } text-white dark:text-white hover:text-white dark:hover:text-white hover:rounded-md-2 hover:bg-neutral-dark dark:hover:bg-neutral-dark data-[active]:text-white data-[active]:bg-neutral-dark/50 dark:data-[state=open]:bg-neutral-dark/50 data-[state=open]:bg-neutral-dark/50 data-[state=open]:text-white`}
              >
                {/* <UsersIcon className="mr-2" stroke={DefaultNavbarIconStroke} /> */}
                <span className="mr-1">User</span>
                <ChevronDownIcon className="h-3 w-3 text-gray-300" />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-50 max-h-[600px] overflow-y-scroll">
                <ul className="flex flex-col space-y-2.5 p-3">
                  {UserTypes.map((userType) => {
                    const data = UserTypeDisplayInfo[userType];
                    return (
                      <Link to={getLinkWithWorkspace(data.path)} key={data.path} reloadDocument>
                        <div className="p-3.5 rounded-lg w-[250px]" style={{ background: data.color }}>
                          <div className="flex items-center mb-3">
                            <img src={data.imageSrc} className="w-8 h-8 mr-2" alt={`${data.title} icon`} />
                            <p className="text-md font-semibold text-gray-950">{data.title}</p>
                          </div>
                          <p className="text-xs font-normal text-gray-700 mb-4">{data.description}</p>
                          {userType === UserType.COHORTS ? (
                            <p className="text-primary-dark text-sm font-semibold">{`+ Create ${data.title}`}</p>
                          ) : null}
                        </div>
                      </Link>
                    );
                  })}
                </ul>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <Link to={getLinkWithWorkspace('cohort/list')} className={navigationMenuTriggerStyle()}>
              {/* <UsersIcon className="mr-2" stroke={DefaultNavbarIconStroke} /> */}
              Cohorts
            </Link>
          ),
      },
      {
        key: '3',
        item: isDemoWorkspace ? (
          <Tooltip content="Reach out to Percept Insight team for a demo of this feature">
            <div className={navigationMenuTriggerStyle()}>
              {/* <EngageIcon className="mr-2" stroke={DefaultNavbarIconStroke} /> */}
              <span className="mr-1">Engage</span>
              <LockClosedIcon />
            </div>
          </Tooltip>
        ) : userInfo &&
          !isWorkspaceCreatedViaOnboardingFlow &&
          [...ELEVATED_ACCESS_ROLES, MARKETING_ANALYST].includes(userInfo.roleName) ? (
          <DropdownMenu>
            <DropdownMenuTrigger
              className={`${
                isSdkIntegrated ? '' : 'opacity-70'
              } text-white dark:text-white hover:text-white dark:hover:text-white hover:rounded-md-2 hover:bg-neutral-dark dark:hover:bg-neutral-dark data-[active]:text-white data-[active]:bg-neutral-dark/50 dark:data-[state=open]:bg-neutral-dark/50 data-[state=open]:bg-neutral-dark/50 data-[state=open]:text-white`}
            >
              {/* <EngageIcon className="mr-2" stroke={DefaultNavbarIconStroke} /> */}
              <span className="mr-1">Engage</span>
              <ChevronDownIcon className="h-3 w-3 text-gray-300" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-50 max-h-[600px] overflow-y-scroll">
              <ul className="flex flex-col space-y-2.5 p-3">
                {EngageTypes.map((engageType) => {
                  const data = EngageTypeDisplayInfo[engageType];
                  return (
                    <Link to={getLinkWithWorkspace(data.path)} key={data.path} reloadDocument>
                      <div className="p-3.5 rounded-lg w-[250px]" style={{ background: data.color }}>
                        <div className="flex items-center mb-3">
                          <img src={data.imageSrc} className="w-8 h-8 mr-2" alt={`${data.title} icon`} />
                          <p className="text-md font-semibold text-gray-950">{data.title}</p>
                        </div>
                        <p className="text-xs font-normal text-gray-700 mb-4">{data.description}</p>
                        <p className="text-primary-dark text-sm font-semibold">{`+ Create ${data.title}`}</p>
                      </div>
                    </Link>
                  );
                })}
              </ul>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : null,
      },
      {
        key: '4',
        item: (
          <Link
            to={getLinkWithWorkspace('events')}
            className={cn(navigationMenuTriggerStyle(), isSdkIntegrated ? '' : 'opacity-70')}
          >
            {/* <EventsIcon stroke={DefaultNavbarIconStroke} className="mr-2" /> */}
            Events
          </Link>
        ),
      },
      {
        key: '5',
        item: isDemoWorkspace ? (
          <Tooltip content="Reach out to Percept Insight team for a demo of this feature">
            <div className={cn(navigationMenuTriggerStyle(), isSdkIntegrated ? '' : 'opacity-70')}>
              {/* <AlertsIcon className="mr-2" stroke={DefaultNavbarIconStroke} /> */}
              <span className="mr-1">Alerts</span>
              <LockClosedIcon />
            </div>
          </Tooltip>
        ) : (
          <Link
            to={getLinkWithWorkspace('alert/list')}
            className={cn(navigationMenuTriggerStyle(), isSdkIntegrated ? '' : 'opacity-70')}
          >
            {/* <AlertsIcon className="mr-2" stroke={DefaultNavbarIconStroke} /> */}
            Alerts
          </Link>
        ),
      },
      {
        key: '6',
        item: userInfo && canShowExperiments && (
          <Link
            to={getLinkWithWorkspace('experiment/list')}
            className={cn(navigationMenuTriggerStyle(), isSdkIntegrated ? '' : 'opacity-70')}
          >
            {/* <ExperimentIcon stroke={DefaultNavbarIconStroke} className="mr-2" /> */}
            Experiments
          </Link>
        ),
      },
      {
        key: '7',
        item: userInfo && isFeatureFlagEnabled && (
          <Link to={getLinkWithWorkspace('feature-flag/list')} className={navigationMenuTriggerStyle()}>
            Feature Flag
          </Link>
        ),
      },
      {
        key: '8',
        item: userInfo &&
          isHeatMapEnabled &&
          ((isWorkspaceCreatedViaOnboardingFlow && sdks.findIndex((s) => s.sdkType === 'Web') > -1) || true) && (
            <Link
              to={getLinkWithWorkspace('heatmaps/dashboard')}
              className={cn(navigationMenuTriggerStyle(), isSdkIntegrated ? '' : 'opacity-70')}
            >
              {/* <HeatMapIcon className="mr-2" stroke={DefaultNavbarIconStroke} /> */}
              Hotspots
            </Link>
          ),
      },
    ],
    [userInfo, isSdkIntegrated, sdks, isWorkspaceCreatedViaOnboardingFlow], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <NavMenu navbarItems={navItems} />;
}
