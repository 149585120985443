import { atom } from 'recoil';

import { SdkInfo } from './types';

export const sdkApiToken = atom<string>({
  key: 'sdkApiToken',
  default: '',
});

export const sdkIntegrationError = atom<Error | unknown>({
  key: 'sdkIntegrationError',
  default: undefined,
});

export const integratedSdks = atom<SdkInfo[]>({
  key: 'integratedSdks',
  default: [],
});
