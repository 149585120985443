import {
  getCohortsList,
  getCustomEvents,
  getProperties,
  getValuesByCohortName,
  getValuesByEventName,
} from '@src/client/lib/api/queries/common';
import { QueryKeys } from '@src/client/lib/api/queries/keys';
import { CohortBasicItem, CustomEventDTO, DimensionDetailed, DimensionType } from '@src/client/lib/api/types/response';
import { getUseQueryOptions } from '@src/client/lib/api/utils';
import { isLengthyArray } from '@src/client/lib/utils';
import { getSdkItegrations } from '@src/client/modules/sdk-integration/api/queries';
import { integratedSdks } from '@src/client/modules/sdk-integration/atoms';
import { SdkInfo, SdkTypesForIntegrationTest } from '@src/client/modules/sdk-integration/types';
import { activeWorkspaceState } from '@src/client/recoil/atoms';
import { EventsIcon } from '@src/client/ui-library/icons/NavbarIcons';
import {
  CohortDropdownIcon,
  EventDropdownIcon,
  EventPropertyDropdownIcon,
} from '@src/client/ui-library/icons/ReportIcons';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CustomEventType } from '../../modules/custom-events/types';
import { EventsAndCohorts, PropertiesAndCohorts } from './types';
import { addTimeDimensionsInProperties, getCustomEventDimensionType } from './uiHelpers';

export const usePropertiesWithCohorts = () => {
  const [propertiesAndCohortsList, setPropertiesAndCohortsList] = useState<PropertiesAndCohorts[]>([]);
  const [timestampProperties, setTimestampProperties] = useState<PropertiesAndCohorts[]>([]);
  const [properties, setProperties] = useState<DimensionDetailed[]>([]);

  const fetchProperties = useQuery([{ key: QueryKeys.GetDetailedDimensions }], getProperties, getUseQueryOptions());
  const fetchCohorts = useQuery([QueryKeys.GetCohorts], getValuesByCohortName, getUseQueryOptions());

  useEffect(() => {
    if (fetchCohorts.isSuccess && fetchProperties.isSuccess) {
      const tempProperties: PropertiesAndCohorts[] = fetchProperties.data
        .filter((i) => i.column !== '__time')
        .map((property) => ({
          label: property.column,
          value: property.column,
          type: DimensionType.PROPERTY,
          propertyCached: property.cached,
          icon: <EventPropertyDropdownIcon />,
        }));
      const tempCohorts: PropertiesAndCohorts[] = fetchCohorts.data.map(
        (cohortValue: CohortBasicItem): PropertiesAndCohorts => ({
          label: cohortValue.name,
          value: cohortValue.itemExternalId,
          type: DimensionType.COHORT,
          propertyCached: false,
          icon: <CohortDropdownIcon />,
        }),
      );
      setPropertiesAndCohortsList(tempProperties.concat(tempCohorts));
    }
  }, [fetchCohorts.isSuccess, fetchProperties.isSuccess, fetchCohorts.data, fetchProperties.data]);

  useEffect(() => {
    if (fetchProperties.isSuccess) {
      const filteredPropertiesDat: DimensionDetailed[] = fetchProperties.data.filter((i) => i.column !== '__time');
      const propertiesWithTimeDimension = addTimeDimensionsInProperties(filteredPropertiesDat);

      setProperties(propertiesWithTimeDimension);
    }
  }, [fetchProperties.isSuccess, fetchProperties.data]);

  useEffect(() => {
    const timeDimensions = addTimeDimensionsInProperties([]);
    setTimestampProperties(
      timeDimensions.map(
        (timeDimension): PropertiesAndCohorts => ({
          label: timeDimension.column,
          value: timeDimension.column,
          type: DimensionType.TIME,
          propertyCached: timeDimension.cached,
          icon: <EventsIcon />,
        }),
      ),
    );
  }, []);

  return {
    isLoading: fetchProperties.isLoading || fetchCohorts.isLoading,
    propertiesAndCohortsList,
    properties,
    timestampProperties,
    propertiesCohortsAndTimestampProps: [...propertiesAndCohortsList, ...timestampProperties],
    cohorts: fetchCohorts.data,
  };
};

export const useDimensionsWithCohorts = (dimension: string) => {
  const [eventsAndCohortList, setEventsAndCohortList] = useState<EventsAndCohorts[] | []>([]);
  const fetchEvents = useQuery([QueryKeys.GetAllEvents], () => getValuesByEventName(dimension), getUseQueryOptions(2));
  const fetchCohorts = useQuery([QueryKeys.GetCohorts], getValuesByCohortName, getUseQueryOptions());

  useEffect(() => {
    if (fetchEvents.isSuccess && fetchCohorts.isSuccess) {
      const tempEvents = fetchEvents.data.map(
        (value: string): EventsAndCohorts => ({
          label: value,
          value,
          icon: <EventDropdownIcon />,
          resource_type: DimensionType.EVENT,
        }),
      );
      const tempCohorts = fetchCohorts.data.map(
        (cohortValue: CohortBasicItem): EventsAndCohorts => ({
          label: cohortValue.name,
          value: cohortValue.itemExternalId,
          icon: <CohortDropdownIcon />,
          resource_type: DimensionType.COHORT,
        }),
      );
      setEventsAndCohortList(tempEvents.concat(tempCohorts));
    }
  }, [fetchCohorts.isSuccess, fetchEvents.isSuccess, fetchCohorts.data, fetchEvents.data]);

  return {
    isLoading: fetchEvents.isLoading || fetchCohorts.isLoading,
    eventsAndCohortList,
    eventsList: fetchEvents.data || [],
    cohortsList: fetchCohorts.data || [],
  };
};

/* Includes events, custom_events & cohorts */
export const useAllDimensions = (dimension: string) => {
  const [dimensionsList, setDimensionList] = useState<EventsAndCohorts[] | []>([]);
  const fetchEvents = useQuery([QueryKeys.GetAllEvents], () => getValuesByEventName(dimension), getUseQueryOptions(2));
  const fetchCohorts = useQuery(QueryKeys.GetCohorts, getValuesByCohortName, getUseQueryOptions());
  const fetchCustomEvents = useQuery(
    [QueryKeys.GetCustomEvents],
    () =>
      getCustomEvents({
        limit: 100,
        offset: 0,
        currentUserOnly: false,
      }),
    getUseQueryOptions(),
  );

  useEffect(() => {
    if (fetchEvents.isSuccess && fetchCohorts.isSuccess && fetchCustomEvents.isSuccess) {
      const tempEvents = fetchEvents.data.map(
        (value: string): EventsAndCohorts => ({
          label: value,
          value,
          icon: <EventDropdownIcon />,
          resource_type: DimensionType.EVENT,
        }),
      );
      const tempCohorts = fetchCohorts.data.map(
        (cohortValue: CohortBasicItem): EventsAndCohorts => ({
          label: cohortValue.name,
          value: cohortValue.itemExternalId,
          icon: <CohortDropdownIcon />,
          resource_type: DimensionType.COHORT,
        }),
      );
      const tempCustomEvents = fetchCustomEvents.data
        .filter(({ type }) =>
          [CustomEventType.CUSTOM_EVENT, CustomEventType.VIRTUAL_EVENT, CustomEventType.EVENT_GROUP].includes(type),
        )
        .map(
          (customEvent: CustomEventDTO): EventsAndCohorts => ({
            label: customEvent.name,
            value: customEvent.itemExternalId,
            icon: <EventDropdownIcon />,
            resource_type: getCustomEventDimensionType(customEvent.type),
          }),
        );
      setDimensionList([...tempEvents, ...tempCustomEvents, ...tempCohorts]);
    }
  }, [
    fetchCohorts.isSuccess,
    fetchEvents.isSuccess,
    fetchCohorts.data,
    fetchEvents.data,
    fetchCustomEvents.data,
    fetchCustomEvents.isSuccess,
  ]);
  return {
    isLoading: fetchEvents.isLoading || fetchCohorts.isLoading || fetchCustomEvents.isLoading,
    dimensionsList,
    eventsList: fetchEvents.data || [],
    cohortsList: fetchCohorts.data || [],
    customEventsList: fetchCustomEvents.data || [],
  };
};

/* Includes events & custom_events */
export const useAllEvents = (dimension: string, type: CustomEventType | undefined) => {
  const [dimensionsList, setDimensionList] = useState<EventsAndCohorts[] | []>([]);
  const fetchEvents = useQuery([QueryKeys.GetAllEvents], () => getValuesByEventName(dimension), getUseQueryOptions(2));
  const [eventDimensionList, setEventDimensionList] = useState<EventsAndCohorts[] | []>([]);
  const fetchCustomEvents = useQuery(
    [QueryKeys.GetCustomEvents],
    () =>
      getCustomEvents({
        limit: 100,
        offset: 0,
        currentUserOnly: false,
        type,
      }),
    getUseQueryOptions(),
  );

  useEffect(() => {
    if (fetchEvents.isSuccess && fetchCustomEvents.isSuccess) {
      const tempEvents = fetchEvents.data.map(
        (value: string): EventsAndCohorts => ({
          label: value,
          value,
          icon: <EventDropdownIcon />,
          resource_type: DimensionType.EVENT,
        }),
      );
      const tempCustomEvents = fetchCustomEvents.data
        .filter((evt) =>
          [CustomEventType.CUSTOM_EVENT, CustomEventType.VIRTUAL_EVENT, CustomEventType.EVENT_GROUP].includes(evt.type),
        )
        .map(
          (customEvent: CustomEventDTO): EventsAndCohorts => ({
            label: customEvent.name,
            value: customEvent.itemExternalId,
            icon: <EventDropdownIcon />,
            resource_type: getCustomEventDimensionType(customEvent.type),
          }),
        );
      setDimensionList([...tempEvents, ...tempCustomEvents]);
      setEventDimensionList(tempEvents);
    }
  }, [fetchEvents.isSuccess, fetchEvents.data, fetchCustomEvents.data, fetchCustomEvents.isSuccess]);
  return {
    isLoading: fetchEvents.isLoading || fetchCustomEvents.isLoading,
    dimensionsList,
    eventsList: fetchEvents.data || [],
    eventDimensionsList: eventDimensionList,
    customEventsList: fetchCustomEvents.data || [],
  };
};

export const useAllCohorts = (): {
  isLoading: boolean;
  cohorts: EventsAndCohorts[];
} => {
  const [cohortList, setCohortList] = useState<EventsAndCohorts[] | []>([]);
  const fetchCohorts = useQuery([QueryKeys.GetCohorts], getCohortsList, getUseQueryOptions());

  useEffect(() => {
    if (fetchCohorts.isSuccess) {
      const tempCohorts = fetchCohorts.data.map((cohortValue: CohortBasicItem) => ({
        label: cohortValue.name,
        value: cohortValue.itemExternalId,
        icon: <CohortDropdownIcon />,
        resource_type: DimensionType.COHORT,
      }));
      setCohortList(tempCohorts);
    }
  }, [fetchCohorts.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isLoading: fetchCohorts.isLoading,
    cohorts: cohortList,
  };
};

export const useAllProperties = () => {
  const [properties, setProperties] = useState<PropertiesAndCohorts[] | []>([]);
  const fetchProperties = useQuery([{ key: QueryKeys.GetDetailedDimensions }], getProperties, getUseQueryOptions());

  useEffect(() => {
    if (fetchProperties.isSuccess) {
      const tempProperties = fetchProperties.data
        .filter((i) => i.column !== '__time')
        .map((val: DimensionDetailed) => ({
          label: val.column,
          value: `${val.column}`,
          icon: <EventPropertyDropdownIcon />,
          type: val.dataType as DimensionType,
          propertyCached: val.cached,
        }));
      setProperties(tempProperties);
    }
  }, [fetchProperties.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: fetchProperties.isLoading,
    properties,
  };
};

/* Includes events & custom_events */
export const useEvents = () => {
  const fetchEvents = useQuery(
    [QueryKeys.GetAllEvents],
    () => getValuesByEventName('event_name'),
    getUseQueryOptions(2),
  );

  return {
    isLoading: fetchEvents.isLoading,
    eventsList: (fetchEvents.data || []).map(
      (value: string): EventsAndCohorts => ({
        label: value,
        value,
        icon: <EventDropdownIcon />,
        resource_type: DimensionType.EVENT,
      }),
    ),
  };
};

export const useIsDefaultReport = () => {
  const [searchParams] = useSearchParams();

  return !!searchParams.get('isDefault');
};

export const useIsOnboardingCreatedWorkspace = () => {
  const activeWorkspace = useRecoilValue(activeWorkspaceState);
  if (!activeWorkspace) return false;

  return activeWorkspace.tenantId.startsWith('onb');
};

export const useCheckIfSdkIntegratedForOnboardingFlowClient = (): { isSdkIntegrated: boolean; isFetching: boolean } => {
  const isWorkspaceCreatedViaOnboardingFlow = useIsOnboardingCreatedWorkspace();
  const [isLoading, setIsLoading] = useState(true);
  // const [integratedSdks, setIntergratedSdks] = useState<SdkInfo[]>([]);
  const [sdks, setSdks] = useRecoilState(integratedSdks);

  const sdkCheckReq = useMutation(() => getSdkItegrations(Object.values(SdkTypesForIntegrationTest)));

  useEffect(() => {
    if (isWorkspaceCreatedViaOnboardingFlow) {
      sdkCheckReq
        .mutateAsync()
        .then((res) => {
          setIsLoading(false);
          const map = new Map<string, SdkInfo>();
          [...sdks, ...res.data].forEach((item) => map.set(item.sdkType, item));
          setSdks(Array.from(map.values()));
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [isWorkspaceCreatedViaOnboardingFlow]); //  eslint-disable-line react-hooks/exhaustive-deps

  if (!isWorkspaceCreatedViaOnboardingFlow) return { isSdkIntegrated: true, isFetching: false };

  return { isSdkIntegrated: !isLoading && isLengthyArray(sdks), isFetching: isLoading };
};
