/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useIsScopeAvailable } from '@src/client/helpers/access/hooks';
import { AccessScopes } from '@src/client/helpers/access/types';
import { useCheckIfSdkIntegratedForOnboardingFlowClient } from '@src/client/helpers/reports/hooks';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { useToast } from '@src/client/ui-library/toast/use-toast';
import { Link } from 'react-router-dom';

import NavbarMenu from './NavbarMenu';
import NavbarSearch from './NavbarSearch';
import SettingsMenu from './SettingsMenu';
import WalletMenu from './WalletMenu';
import WorkspaceSelector from './WorkspaceSelector';

function PerceptLogo() {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  return (
    <Link to={getLinkWithWorkspace('home')} className="mr-4 flex">
      <img alt="percept-logo" width={112} src="/images/pi_logo_white.svg" />
    </Link>
  );
}

function NavDisabledOverlay() {
  const { isSdkIntegrated } = useCheckIfSdkIntegratedForOnboardingFlowClient();
  const { toast } = useToast();

  const handleNavClicks = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    toast({ variant: 'warning', title: 'Nav menu is disabled', description: 'Please integrate SDK first' });
  };

  if (!isSdkIntegrated) {
    return (
      <div
        className="absolute left-0 right-0 top-0 bottom-0 z-10 cursor-not-allowed"
        role="presentation"
        onKeyDown={handleNavClicks}
        onClick={handleNavClicks}
      />
    );
  }

  return null;
}
export default function SiteNavbar() {
  const isScopeAvailable = useIsScopeAvailable();

  return (
    <header className="flex fixed top-0 left-0 right-0 h-navbar bg-neutral px-4 z-[1000]">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-start relative">
          <PerceptLogo />
          <NavbarMenu />
          <NavDisabledOverlay />
        </div>
        <div className="flex items-center justify-start">
          <NavbarSearch />
          {isScopeAvailable(AccessScopes.WALLET_READ) && <WalletMenu />}
          <SettingsMenu />
          <WorkspaceSelector />
        </div>
      </div>
    </header>
  );
}
