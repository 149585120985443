export enum EventNames {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  PAGE_VIEW = 'PAGE_VIEW',
  APP_OPEN = 'APP_OPEN',

  VITE_PRELOAD_ERROR = 'VITE_PRELOAD_ERROR',

  // Reports related events
  REPORT_FETCH_SUCCESS = 'REPORT_FETCH_SUCCESS',
  REPORT_FETCH_FAILURE = 'REPORT_FETCH_FAILURE',

  // Reports perf related events
  REPORT_RENDER_TIME = 'REPORT_RENDER_TIME',
  LLM_RENDER_TIME = 'LLM_RENDER_TIME',

  // LLM related events
  LLM_QUERY_TRIGGERED = 'LLM_QUERY_TRIGGERED',
  LLM_QUERY_SUCCESS = 'LLM_QUERY_SUCCESS',
  LLM_QUERY_FAILURE = 'LLM_QUERY_FAILURE',
  LLM_GRAPH_SUCCESS = 'LLM_GRAPH_SUCCESS',
  LLM_GRAPH_FAILURE = 'LLM_GRAPH_FAILURE',

  // Error
  ERROR = 'ERROR',

  // Web Vitals
  WEB_VITALS = 'WEB_VITALS',
  CLS = 'CLS',
  FID = 'FID',
  LCP = 'LCP',
  TTFB = 'TTFB',
  INP = 'INP',
  FCP = 'FCP',

  // Funnel Dropoff
  VIEW_FUNNEL_DROP_OFF = 'VIEW_FUNNEL_DROP_OFF',

  // Settings
  CUSTOM_EVENTS_PAGE_CLICKED = 'CUSTOM_EVENTS_PAGE_CLICKED',
  NAVBAR_SETTING_ICON_CLICKED = 'NAVBAR_SETTING_ICON_CLICKED',
  MANAGE_USERS_CLICKED = 'MANAGE_USERS_CLICKED',
  ENGAGE_SETTINGS_CLICKED = 'ENGAGE_SETTINGS_CLICKED',
  DATA_GOVERNANCE_CLICKED = 'DATA_GOVERNANCE_CLICKED',

  // Teams related events
  TEAM_VIEWED = 'TEAM_VIEWED',
  TEAM_DELETE_CLICK = 'TEAM_DELETE_CLICK',
  TEAM_DELETE_CONFIRM = 'TEAM_DELETE_CONFIRM',
  TEAM_UPDATED = 'TEAM_UPDATED',
  TEAM_USERS_ADD = 'TEAM_USERS_ADD',
  TEAM_USER_REMOVE_CLICK = 'TEAM_USER_REMOVE_CLICK',
  TEAM_USER_REMOVE_CONFIRM = 'TEAM_USER_REMOVE_CONFIRM',
  ADD_TEAM_BTN_CLICK = 'ADD_TEAM_BTN_CLICK',
  TEAM_CREATED = 'TEAM_CREATED',
  TEAM_TAB_CLICK = 'TEAM_TAB_CLICK',

  // User Related Events
  USER_TAB_CLICK = 'USER_TAB_CLICK',
  USER_ROLE_UPDATE = 'USER_ROLE_UPDATE',
  USER_DELETE_CLICK = 'USER_DELETE_CLICK',
  USER_DELETE_CONFIRM = 'USER_DELETE_CONFIRM',
  USER_ADD_BTN_CLK = 'USER_ADD_BUTTON_CLICK',

  WRONG_REQUEST_DATA_RECIEVED_FROM_WROKER = 'WRONG_REQUEST_DATA_RECIEVED_FROM_WROKER',
  EMPTY_SERVER_RESPONSE_ERROR = 'EMPTY_SERVER_RESPONSE_ERROR',

  VIEW_USERS_LIST = 'VIEW_USERS_LIST',
  DOWNLOAD_VIEW_USERS_LIST = 'DOWNLOAD_VIEW_USERS_LIST',
  SDK_INTEGRATIONS_CLICKED = 'SDK_INTEGRATIONS_CLICKED',

  REPORT_USER_VIEW_CREATE_COHORT_CLICKED = 'REPORT USER VIEW CREATE COHORT CLICKED ',
  REPORT_USER_VIEW_CREATE_COHORT_PAYLOAD_FAILED = 'REPORT USER VIEW CREATE COHORT PAYLOAD FAILED',
}

export enum EventProperty {
  // User Info
  Tenant = 'tenant',
  Workspace = 'workspace',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  PrivilegeLevel = 'privilegeLevel',
  RoleName = 'roleName',

  // LLM
  Query = 'query',
  ReportUrl = 'reportUrl',

  // Report
  ReportType = 'reportType',
  ItemExternalId = 'itemExternalId',
  RunId = 'runId',

  // Error
  ErrorMessage = 'errorMessage',
  ErrorTag = 'errorTag',
  ErrorStack = 'errorStack',

  // WebVItals
  MetricId = 'metricId',
  MetricName = 'metricName',
  MetricValue = 'metricValue',
  MetricRating = 'metricRating',
  MetricNavigationType = 'metricNavigationType',
  MetricDebugTarget = 'metricDebugTarget',

  // Perf related
  ClientTime = 'clientTime',
  PerceptServerTime = 'perceptServerTime',
  TotalTime = 'totalTime',
  LLMTime = 'llmTime',

  // Team Related
  TeamId = 'teamId',
  TeamName = 'teamName',

  DeletedUserId = 'deletedUserId',
  RemovedUserId = 'removedUserId',
  PlatformUserId = 'platformUserId',

  // Experiment
  ExperimentId = 'experimentId',

  // Feature Flag
  FeatureFlagId = 'featureFlagId',
}

export enum ErrorTags {
  AUTHORIZE_ERROR = 'AUTHORIZE ERROR',

  REFRESH_TOKEN_ERROR = 'REFRESH TOKEN ERROR',

  ACTIVE_WORKSPACE_UPDATE_ERROR = 'ACTIVE WORKSPACE UPDATE ERROR',
  CREATE_WORKSPACE_ERROR = 'CREATE_WORKSPACE_ERROR',

  USER_ROLE_UPDATE_ERROR = 'USER ROLE UPDATE ERROR',
  USER_DELETE_ERROR = 'USER DELETE ERROR',
  FETCH_ALL_USERS_ERROR = 'FETCH ALL USERS ERROR',

  CUSTOM_EVENTS_FETCH_ERROR = 'CUSTOM EVENTS FETCH ERROR',
  CUSTOM_EVENT_CREATION_ERROR = 'CUSTOM EVENT CREATION ERROR',
  CUSTOM_EVENT_DELETE_ERROR = 'CUSTOM EVENT DELETE ERROR',

  COHORT_ITEM_FETCH_ERROR = 'COHORT ITEM FETCH ERROR',
  COHORT_CREATE_ERROR = 'COHORT CREATE ERROR',
  COHORT_UPDATE_ERROR = 'COHORT UPDATE ERROR',
  COHORT_DELETE_ERROR = 'COHORT DELETE ERROR',
  COHORT_USER_COUNT_ERROR = 'COHORT USER COUNT ERROR',
  COHORT_FILE_UPLOAD_ERROR = 'COHORT FILE UPLOAD ERROR',
  COHORT_FILE_DOWNLOAD_ERROR = 'COHORT FILE DOWNLOAD ERROR',

  DASHBOARD_DETAILS_FETCH_ERROR = 'DASHBOARD DETAILS FETCH ERROR',
  DASHBOARD_SAVE_ERROR = 'DASHBOARD SAVE ERROR',
  DASHBOARD_UPDATE_ERROR = 'DASHBOARD UPDATE ERROR',
  DASHBOARD_ADD_REPORT_FETCH_ERROR = 'DASHBOARD ADD REPORT FETCH ERROR',
  DELETE_DASHBOARD_ERROR = 'DELETE DASHBOARD ERROR',

  DIMENSION_FETCH_ERROR = 'DIMENSION FETCH ERROR',
  DIMENSION_VALUES_SEARCH_ERROR = 'DIMENSION VALUES SEARCH ERROR',

  INVALID_CREDENTIALS_ERROR = 'INVALID CREDENTIALS ERROR',
  INSIGHT_CONFIG_FETCH_ERROR = 'INSIGHT CONFIG FETCH ERROR',
  INSIGHT_ADD_ERROR = 'INSIGHT ADD ERROR',
  INSIGHT_UPDATE_ERROR = 'INSIGHT UPDATE ERROR',
  INSIGHT_FETCH_RUN_DATA_ERROR = 'INSIGHT FETCH RUN DATA ERROR',
  INSIGHT_DELETE_ERROR = 'INSIGHT DELETE ERROR',
  INSIGHT_DUPLICATE_ERROR = 'INSIGHT DUPLICATE ERROR',

  RETENTION_ADD_ERROR = 'RETENTION ADD ERROR',
  RETENTION_UPDATE_ERROR = 'RETENTION UPDATE ERROR',
  RETENTION_CONFIG_FETCH_ERROR = 'RETENTION CONFIG FETCH ERROR',
  RETENTION_FETCH_RUN_DATA_ERROR = 'RETENTION FETCH RUN DATA ERROR',

  REPORT_DUPLICATE_ERROR = 'REPORT_DUPLICATE_ERROR',
  MY_REPORT_DELETE_ERROR = 'MY_REPORT_DELETE_ERROR',

  FUNNEL_CONFIG_FETCH_ERROR = 'FUNNEL CONFIG FETCH ERROR',
  FUNNEL_ADD_ERROR = 'FUNNEL ADD ERROR',
  FUNNEL_UPDATE_ERROR = 'FUNNEL UPDATE ERROR',
  FUNNEL_FETCH_RUN_DATA_ERROR = 'FUNNEL FETCH RUN DATA ERROR',
  FUNNEL_DELETE_ERROR = 'FUNNEL DELETE ERROR',
  FUNNEL_DUPLICATE_ERROR = 'FUNNEL DUPLICATE ERROR',
  FUNNEL_DOWNLOAD_TREND_TABLE_DATA_ERROR = 'FUNNEL DOWNLOAD TREND TABLE DATA ERROR',
  FUNNEL_DOWNLOAD_STEPS_TABLE_DATA_ERROR = 'FUNNEL DOWNLOAD STEPS TABLE DATA ERROR',

  FUNNEL_DROP_OFF_CONFIG_FETCH_ERROR = 'FUNNEL DROP OFF CONFIG FETCH ERROR',
  FUNNEL_DROP_OFF_DATA_FETCH_ERROR = 'FUNNEL DROP OFF DATA FETCH ERROR',
  FUNNEL_DROP_OFF_PROPERTY_BREAKDOWN_CONFIG_FETCH_ERROR = 'FUNNEL DROP OFF PROPERTY BREAKDOWN CONFIG FETCH ERROR',

  FLOW_CONFIG_FETCH_ERROR = 'FLOW CONFIG FETCH ERROR',
  FLOW_ADD_ERROR = 'FLOW ADD ERROR',
  FLOW_UPDATE_ERROR = 'FLOW UPDATE ERROR',
  FLOW_FETCH_RUN_DATA_ERROR = 'FLOW FETCH RUN DATA ERROR',
  FLOW_DELETE_ERROR = 'FLOW DELETE ERROR',
  FLOW_DUPLICATE_ERROR = 'FLOW DUPLICATE ERROR',

  REPORT_CONFIG_FETCH_ERROR = 'REPORT CONFIG FETCH ERROR',
  REPORT_UPDATE_ERROR = 'REPORT UPDATE ERROR',
  REPORT_SUMMARIES_FETCH_ERROR = 'REPORT SUMMARIES FETCH ERROR',

  ALERT_CONFIG_FETCH_ERROR = 'ALERT CONFIG FETCH ERROR',
  ALERT_SUMMARY_FETCH_ERROR = 'ALERT SUMMARY FETCH ERROR',
  ALERT_LIST_FETCH_ERROR = 'ALERT LIST FETCH ERROR',
  ALERT_UPDATE_ERROR = 'ALERT UPDATE ERROR',
  ALERT_DELETE_ERROR = 'ALERT DELETE ERROR',
  ALERT_CONFIG_UPDATE = 'ALERT CONFIG UPDATE',
  ALERT_CREATE_ERROR = 'ALERT CREATE ERROR',

  ANOMALY_FETCH_ERROR = 'ANOMALY FETCH ERROR',
  ANOMALIES_FETCH_ERROR = 'ANOMALIES FETCH ERROR',

  RECENT_SUMMARY_FETCH_ERROR = 'RECENT SUMMARY FETCH ERROR',
  SEARCH_ERROR = 'SEARCH ERROR',
  SEARCH_MORE_ERROR = 'SEARCH MORE ERROR',
  SEARCH_CREATOR_FETCH_ERROR = 'SEARCH CREATOR FETCH ERROR',

  API_REQUEST_ERROR = 'SERVER ERROR',

  UNHANDLED_RUNTIME_ERROR = 'UNHANDLED RUNTIME ERROR',
  FAILED_DYNAMIC_IMPORT_ERROR = 'FAILED_DYNAMIC_IMPORT_ERROR',

  LLM_QUERY_FAILURE = 'LLM QUERY FAILURE',

  REPORT_PERF_TRACKING_ERROR = 'REPORT PERF TRACKING ERROR',
  LLM_PERF_TRACKING_ERROR = 'LLM PERF TRACKING ERROR',
  DASHBOARD_CARD_PERF_TRACKING_ERROR = 'DASHBOARD CARD PERF TRACKING ERROR',

  ENGAGE_CAMPAIGN_DELETE_ERROR = 'ENGAGE CAMPAIGN DELETE ERROR',
  CAMPAIGN_DETAILS_FETCH_ERROR = 'CAMPAIGN DETAILS FETCH ERROR',
  CAMPAIGN_CREATE_ERROR = 'CAMPAIGN CREATE ERROR',
  CAMPAIGN_UPDATE_ERROR = 'CAMPAIGN UPDATE ERROR',

  FLEXICAST_DELETE_ERROR = 'FLEXICAST DELETE ERROR',
  FLEXICAST_DETAILS_FETCH_ERROR = 'FLEXICAST DETAILS FETCH ERROR',
  FLEXICAST_CREATE_ERROR = 'FLEXICAST CREATE ERROR',
  FLEXICAST_UPDATE_ERROR = 'FLEXICAST UPDATE ERROR',
  FLEXICAST_LIST_FETCH_ERROR = 'FLEXICAST LIST FETCH ERROR',

  EXPERIMENT_LIST_FETCH_ERROR = 'EXPERIMENT LIST FETCH ERROR',
  EXPERIMENT_DELETE_ERROR = 'EXPERIMENT DELETE ERROR',
  EXPERIMENT_DETAILS_FETCH_ERROR = 'EXPERIMENT DETAILS FETCH ERROR',
  EXPERIMENT_CREATE_ERROR = 'EXPERIMENT CREATE ERROR',
  EXPERIMENT_UPDATE_ERROR = 'EXPERIMENT UPDATE ERROR',
  EXPERIMENT_RESULT_FETCH_ERROR = 'EXPERIMENT RESULT FETCH ERROR',
  EXPERIMENT_VERSION_HISTORY_FETCH_ERROR = 'EXPERIMENT VERSION HISTORY FETCH ERROR',

  FEATURE_FLAG_LIST_FETCH_ERROR = 'FEATURE FLAG LIST FETCH ERROR',
  FEATURE_FLAG_DELETE_ERROR = 'FEATURE FLAG DELETE ERROR',
  FEATURE_FLAG_DETAILS_FETCH_ERROR = 'FEATURE FLAG DETAILS FETCH ERROR',
  FEATURE_FLAG_CREATE_ERROR = 'FEATURE FLAG CREATE ERROR',
  FEATURE_FLAG_UPDATE_ERROR = 'FEATURE FLAG UPDATE ERROR',
  FEATURE_FLAG_VERSION_HISTORY_FETCH_ERROR = 'FEATURE FLAG VERSION HISTORY FETCH ERROR',

  TEAM_USERS_FETCH_ERROR = 'TEAM USERS FETCH ERROR',
  TEAM_USERS_COUNT_FETCH_ERROR = 'TEAM USERS COUNT FETCH ERROR',
  TEAM_USERS_ADD_ERROR = 'TEAM USERS ADD ERROR',
  TEAM_USERS_REMOVE_ERROR = 'TEAM USERS REMOVE ERROR',
  TEAM_CREATE_ERROR = 'TEAM CREATE ERROR',
  TEAM_UPDATE_ERROR = 'TEAM UPDATE ERROR',
  TEAM_DELETE_ERROR = 'TEAM DELETE ERROR',
  FETCH_TEAMS_ERROR = 'FETCH TEAMS ERROR',

  EVENTS_CONFIG_CREATION_ERROR = 'EVENTS CONFIG CREATION ERROR',

  HOME_RECENTLY_VIEWED_FETCH_ERROR = 'HOME RECENTLY VIEWED FETCH ERROR',

  CAMPAIGN_LIST_FETCH_ERROR = 'CAMPAIGN_LIST_FETCH_ERROR',

  GLOBAL_LIMIT_UPSERT_ERROR = 'GLOBAL_LIMIT_UPSERT_ERROR',
  GLOBAL_LIMIT_DELETE_ERROR = 'GLOBAL_LIMIT_DELETE_ERROR',

  FUNNEL_WORKER_ERROR = 'FUNNEL_WORKER_ERROR',
  INSIGHT_WORKER_ERROR = 'INSIGHT_WORKER_ERROR',
  UNKNOWN_WROKER_ERROR = 'UNKNOWN_WROKER_ERROR',

  USER_PROFILES_FETCH_ERROR = 'USER PROFILES FETCH ERROR',
  USER_PROFILE_PROPERTIES_FETCH_ERROR = 'USER PROFILE PROPERTIES FETCH ERROR',

  UPDATE_DIMENSION_ERROR = 'UPDATE DIMENSION ERROR',
  UPDATE_EVENT_ERROR = 'UPDATE EVENT ERROR',
  DIMENSION_LIST_FETCH_ERROR = 'DIMENSION LIST FETCH ERROR',
  EVENT_LIST_FETCH_ERROR = 'EVENT LIST FETCH ERROR',
  DATA_GOV_STATUS_FETCH_ERROR = 'DATA GOV STATUS FETCH ERROR',

  DATA_GOV_REMOVE_DIMENSION_ACCESS = 'DATA GOV REMOVE DIMENSION ACCESS',
  DATA_GOV_ADD_DIMENSION_ACCESS = 'DATA GOV ADD DIMENSION ACCESS',

  FETCH_REPORT_USERS_CONFIG_ERROR = 'FETCH REPORT USERS CONFIG ERROR',
  REPORT_USERS_DATA_FETCH_ERROR = 'REPORT USERS DATA FETCH ERROR',
  FETCH_WORKSPACES_ORG = 'FETCH_WORKSPACES_ORG',
  FETCH_SDK_INTEGRATIONS = 'FETCH_SDK_INTEGRATIONS',
  FETCH_WALLET_BALANCE = 'FETCH_WALLET_BALANCE',

  FETCH_ENGAGE_ATTRIBUTION_USERS_ERROR = 'FETCH ENGAGE ATTRIBUTION USERS ERROR',
  FETCH_ENGAGE_ATTRIBUTION_USERS_DOWNLOAD_ERROR = 'FETCH ENGAGE ATTRIBUTION USERS DOWNLOAD ERROR',
  FETCH_IS_WORKSPACES_AVAILABLE = 'FETCH_IS_WORKSPACES_AVAILABLE',

  CREATE_COHORT_FROM_REPORT_ERROR = 'CREATE COHORT FROM REPORT ERROR',
  REPORT_USER_VIEW_CREATE_PAYLOAD_ERROR = 'REPORT USER VIEW CREATE PAYLOAD ERROR',

  DOWNLOAD_COHORT_STREAM_ERROR = 'DOWNLOAD COHORT STREAM ERROR',
  DND_UPSERT_ERROR = 'DND_UPSERT_ERROR',

  SCREENSHOT_FETCH_ERROR = 'SCREENSHOT FETCH ERROR',
  FETCH_WALLET_LEDGER = 'FETCH_WALLET_LEDGER',
}
