export enum SdkType {
  WEB = 'WEB',
  REACT_NATIVE = 'REACT NATIVE',
  FLUTTER = 'FLUTTER',
  JAVA = 'JAVA',
  // ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEBFLOW = 'WEBFLOW',
  SHOPIFY = 'SHOPIFY',
}

export enum SdkTypesForIntegrationTest {
  WEB = 'WEB',
  REACT_NATIVE = 'REACT NATIVE',
  FLUTTER = 'FLUTTER',
  JAVA = 'JAVA',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEBFLOW = 'WEBFLOW',
  SHOPIFY = 'SHOPIFY',
}

export interface SdkIntegrationResponse {
  success: boolean;
  apikey: string;
  data: SdkInfo[];
}

export interface SdkInfo {
  sdkType: string;
  sdkVersion: string;
  sdkTotalEvents: string;
}
