import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { activeWorkspaceState } from '@src/client/recoil/atoms';
import { Button } from '@src/client/ui-library/button';
// import { AnimatedRadarPing } from '@src/client/ui-library/loaders';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export default function DefaultDashboardBanner() {
  const navigate = useNavigate();
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const activeWorkspace = useRecoilValue(activeWorkspaceState);
  const wasDefaultDashboardLinkClicked = activeWorkspace
    ? localStorage.getItem(`percept_default_dashboard_${activeWorkspace.id}_clicked`)
    : true;

  const navigateToDefaultDashboard = () => {
    if (!activeWorkspace) return;
    localStorage.setItem(`percept_default_dashboard_${activeWorkspace.id}_clicked`, 'true');
    navigate(getLinkWithWorkspace('default-dashboard'));
  };

  if (wasDefaultDashboardLinkClicked) return null;

  return (
    <div className="flex justify-between py-2 px-8 mb-4 bg-gray-100 dark:bg-gray-600 border border-border rounded-md items-center">
      <div className="space-x-8 inline-flex items-center">
        <img alt="default-dashboard-placeholder-img" src="/images/v2/smart-reports.svg" className="w-[40px]" />

        <h3 className="font-bold text-sm">Your default dashboard is ready</h3>
      </div>
      <div className="space-x-8 inline-flex items-center">
        <Button onClick={navigateToDefaultDashboard}>View Dashboard</Button>
        {/* <AnimatedRadarPing /> */}
      </div>
    </div>
  );
}
